import React, { useState, useEffect, useCallback } from 'react';
import './style.css';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Header } from '../../Components/Header';
import { Link } from 'react-scroll';
import { GlobalContext } from '../../GlobalContext';
import Cookies from 'universal-cookie';
import Loading from '../../Components/Loading';
import ButtonLink from '../../Components/ButtonLink/ButtonLink';

const { REACT_APP_URL_API } = process.env;
const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};

export function Clube() {
  // const { cliente_id } = useParams();

  // const cliente = React.useContext(GlobalContext);
  // const { data } = React.useContext(GlobalContext);
  // const [value, setValue] = React.useState(0);
  // const handleChange = (event, newValue) => {
  //   setValue(newValue);
  // };

  const handleClose = () => setOpen(0);
  const handleClose2 = () => setOpen2(0);

  const [abrir, setAbrir] = React.useState(false);
  const handleAbrir = () => setAbrir(true);
  const handleFechar = () => setAbrir(false);

  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();
  const { session, whoami } = React.useContext(GlobalContext);
  const teste = cliente_id;

  const [create, setCreate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = React.useState(0);
  const [open2, setOpen2] = React.useState(0);

  var raw = JSON.stringify({
    client_id: teste,
  });

  var raw2 = JSON.stringify({
    client_id: teste,
    voucher_id: '',
  });

  useEffect(() => {
    // function getCookie(name) {
    //   if (!document.cookie) {
    //     return null;
    //   }

    //   const xsrfCookies = document.cookie
    //     .split(';')
    //     .map((c) => c.trim())
    //     .filter((c) => c.startsWith(name + '='));

    //   if (xsrfCookies.length === 0) {
    //     return null;
    //   }
    //   return decodeURIComponent(xsrfCookies[0].split('=')[1]);
    // }

    // const token = getCookie('csrftoken');
    // console.log(token);
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  }, []);

  if (data === null) return null;

  const index1 = () => {
    // function getCookie(name) {
    //   if (!document.cookie) {
    //     return null;
    //   }

    //   const xsrfCookies = document.cookie
    //     .split(';')
    //     .map((c) => c.trim())
    //     .filter((c) => c.startsWith(name + '='));

    //   if (xsrfCookies.length === 0) {
    //     return null;
    //   }
    //   return decodeURIComponent(xsrfCookies[0].split('=')[1]);
    // }

    // const token = getCookie('csrftoken');
    // console.log(token);
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  };

  const handleOpen = (id) => {
    // function getCookie(name) {
    //   if (!document.cookie) {
    //     return null;
    //   }

    //   const xsrfCookies = document.cookie
    //     .split(';')
    //     .map((c) => c.trim())
    //     .filter((c) => c.startsWith(name + '='));

    //   if (xsrfCookies.length === 0) {
    //     return null;
    //   }
    //   return decodeURIComponent(xsrfCookies[0].split('=')[1]);
    // }

    // const token = getCookie('csrftoken');
    // console.log(token);

    index1();
    setTimeout(() => {
      setLoading(true);
    });

    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });
    fetch(`${REACT_APP_URL_API}/apiv2/voucher_creation/`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        client_id: teste,
        voucher_id: id,
      }),
    })
      .then((response) => response.json())

      .then((response) => {
        setCreate(response.status);
        index1();
      })

      .catch((erro) => console.log(erro));

    setTimeout(() => {
      setLoading(false);
      setOpen(id);
    }, 1500);
    index1();
  };

  const handleOpen2 = (id) => {
    setOpen2(id);
  };

  if (loading)
    return (
      <div>
        {' '}
        <Loading />
      </div>
    );
  if (data === null) return null;

  const logoCliente = data[0]?.response.logo;

  // const categorias = data.map((item) => item.response.voucher).flat();
  // const categoriasGeral = categorias.map((item) => item.setor_empresa_parceira);
  // const categoriasUnicas = Array.from(new Set(categoriasGeral));

  // const categoriasFilter = [];
  // const primeiroItemCategoria = data[0].response.voucher
  //   .map((item) => {
  //     if (!categoriasFilter.includes(item.setor_empresa_parceira)) {
  //       categoriasFilter.push(item.setor_empresa_parceira);

  //       return item.id;
  //     }
  //   })
  //   .filter((i) => !!i);

  const categoriasUnicas = [];
  const categoriasFilter = [];
  const categorias = data.map((item) => item.response.voucher).flat();
  const categoriasGeral = categorias.map((item) => {
    if (!categoriasUnicas.includes(item.setor_empresa_parceira)) {
      categoriasUnicas.push(item.setor_empresa_parceira);
      categoriasFilter.push(item.id);
    }
  });

  function compare(a, b) {
    if (a.setor_empresa_parceira < b.setor_empresa_parceira) {
      return -1;
    } else {
      return true;
    }
  }

  return (
    <div style={{ background: 'var(--background-main)' }}>
      <Header />
      <div className="categorias">
        <ul style={{ marginTop: '40px' }}>
          {categoriasUnicas.sort().map((item) => (
            <li>
              <Link
                className={item}
                to={item}
                spy={item}
                smooth={true}
                duration={500}
                offset={-99}
                activeClass="activeCategoryLink"
                style={{ textDecoration: 'none', color: 'var(--tertiary)' }}
              >
                {item}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {data.map((item) => (
        <Box
          sx={{
            maxWidth: '930px',
            margin: '120px auto',
            left: '1%',
            right: '1%',
            bgcolor: 'var(--background-main)',
          }}
          key={item.id}
        >
          {item.response.voucher.sort(compare).map((cupom) => (
            <div key={cupom.id}>
              {/* {console.log('show', cupom)} */}
              {cupom.status === true ? (
                <div
                  className={cupom.setor_empresa_parceira}
                  name={cupom.setor_empresa_parceira}
                >
                  <div className="container-clube-voucher">
                    {categoriasFilter.includes(cupom.id) ? (
                      <h2
                        className="title-sorteio"
                        style={{ color: 'var(--secondary)' }}
                      >
                        {cupom.setor_empresa_parceira}
                      </h2>
                    ) : null}
                    {/* {console.log('cupom', cupom)} */}
                    <div className="box container-card-voucher">
                      <div className="voucher-container card-voucher-container">
                        {!cupom.custom_logo && 'null' ? (
                          <a href={`/${cliente_id}/${cupom.id}/parceiro`}>
                            <img
                              src={
                                `${REACT_APP_URL_API}/media/` +
                                cupom.logo_empresa_parceira
                              }
                            />
                          </a>
                        ) : (
                          <a href={`/${cliente_id}/${cupom.id}/parceiro`}>
                            {' '}
                            <img
                              src={`${REACT_APP_URL_API}` + cupom.custom_logo}
                            />
                          </a>
                        )}

                        <p style={{ color: 'var(--secondary)' }}>
                          {' '}
                          {cupom.descricao}
                        </p>

                        <div className="box-button btn-ver-voucher">
                          {cupom.controle[0] === null && (
                            <button
                              style={{
                                background: 'var(--secondary)',
                                color: 'var(--background)',
                              }}
                              onClick={handleAbrir}
                            >
                              Ver voucher
                            </button>
                          )}

                          {whoami.isAuthenticated !== false &&
                          cupom.controle[0] === 'create' ? (
                            <button
                              id="cupom.id"
                              onClick={() => handleOpen(cupom.id)}
                              style={{
                                backgroundImage: `linear-gradient(135deg, ${cupom.custom_color1} , ${cupom.custom_color2} )`,
                              }}
                            >
                              Gerar voucher
                            </button>
                          ) : null}

                          {whoami.isAuthenticated !== true &&
                            cupom.controle[0] == 'show' && (
                              <button
                                style={{
                                  background: 'var(--background-main)',
                                  border: 'dashed 1px var(--primary)',
                                  color: 'var(--primary)',
                                }}
                                id="cupom.id"
                                onClick={() => handleOpen2(cupom.id)}
                              >
                                Ver voucher
                              </button>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {cupom.controle[0] === 'show' ? (
                    <Modal
                      open={cupom.id === open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      id={cupom.id}
                    >
                      <div className="container-card">
                        <div>
                          <h1 className="description">
                            Voucher gerado com sucesso
                          </h1>
                        </div>

                        <div
                          className="cupom-card"
                          style={{
                            background: `linear-gradient(135deg, ${cupom.custom_color1} , ${cupom.custom_color2} )`,
                          }}
                        >
                          <div className="div-img-cupom">
                            <img
                              src={`${REACT_APP_URL_API}` + logoCliente}
                              className="logo-cupom"
                            />
                            {!cupom.custom_logo && 'null' ? (
                              <img
                                src={
                                  `${REACT_APP_URL_API}/media/` +
                                  cupom.logo_empresa_parceira
                                }
                                className="logo-cupom"
                                id={cupom.id}
                                alt=""
                              />
                            ) : (
                              <img
                                src={`${REACT_APP_URL_API}` + cupom.custom_logo}
                                className="logo-cupom"
                                id={cupom.id}
                                alt=""
                              />
                            )}
                          </div>
                          <h2 className="desconto" id={cupom.id}>
                            {cupom.desconto}
                          </h2>

                          <div className="container-descricao">
                            <h2 id={cupom.id}>{cupom.descricao}</h2>
                          </div>

                          <div className="cupom-row">
                            {/* <span className="cupom-code"></span> */}

                            {cupom.controle[0] === 'show' ? (
                              <span>
                                {cupom.url !== null ? (
                                  <button className="btn-url">
                                    <a href={cupom.url} target="_blank">
                                      Acessar Vantagem
                                    </a>
                                  </button>
                                ) : (
                                  <span className="cupom-code">
                                    {cupom.controle[1]}
                                  </span>
                                )}
                              </span>
                            ) : null}
                          </div>

                          <p> valido até {cupom.controle[2]}</p>

                          <button className="fechar" onClick={handleClose}>
                            X
                          </button>
                        </div>
                      </div>
                    </Modal>
                  ) : null}

                  {whoami.isAuthenticated !== false ? (
                    <Modal
                      open={cupom.id === open2}
                      onClose={handleClose2}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                      id={cupom.id}
                    >
                      <div className="container-card">
                        <div>
                          <h1 className="description">
                            Voucher gerado com sucesso
                          </h1>
                        </div>

                        <div
                          className="cupom-card"
                          style={{
                            background: `linear-gradient(135deg, ${cupom.custom_color1} , ${cupom.custom_color2} )`,
                          }}
                        >
                          <div className="div-img-cupom">
                            <img
                              src={`${REACT_APP_URL_API}` + logoCliente}
                              className="logo-cupom"
                            />

                            {!cupom.custom_logo && 'null' ? (
                              <img
                                src={
                                  `${REACT_APP_URL_API}/media/` +
                                  cupom.logo_empresa_parceira
                                }
                                className="logo-cupom"
                                id={cupom.id}
                                alt=""
                              />
                            ) : (
                              <img
                                src={`${REACT_APP_URL_API}` + cupom.custom_logo}
                                className="logo-cupom"
                                id={cupom.id}
                                alt=""
                              />
                            )}
                          </div>

                          <h2 className="desconto" id={cupom.id}>
                            {cupom.desconto}
                          </h2>

                          <div className="container-descricao">
                            <h2 id={cupom.id}>{cupom.descricao}</h2>
                          </div>

                          <div className="cupom-row">
                            {/* <span className="cupom-code"></span> */}

                            {cupom.controle[0] === 'show' ? (
                              <span>
                                {cupom.url !== null ? (
                                  <button className="btn-url">
                                    <a href={cupom.url} target="_blank">
                                      Acessar Vantagem
                                    </a>
                                  </button>
                                ) : (
                                  <span className="cupom-code">
                                    {cupom.controle[1]}
                                  </span>
                                )}
                              </span>
                            ) : null}
                          </div>

                          <p> valido até {cupom.controle[2]}</p>

                          <button className="fechar" onClick={handleClose2}>
                            X
                          </button>
                        </div>
                      </div>
                    </Modal>
                  ) : null}
                </div>
              ) : null}
            </div>
          ))}
        </Box>
      ))}
      <Modal
        open={abrir}
        onClose={handleFechar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p
              style={{
                color: 'var(--title)',
                padding: '10px',
                marginBottom: '20px',
              }}
            >
              Você não está logado
            </p>
            <div>
              {' '}
              {/* <a
                href="auth"
                style={{
                  color: '#fff',
                  padding: '10px',
                  fontSize: '20px',
                  background: 'var(--quartary)',
                  marginTop: '30px',
                  borderRadius: '8px',
                }}
              >
                Fazer login
              </a> */}
              <ButtonLink to={`/${cliente_id}/auth`}> Fazer login</ButtonLink>
            </div>

            <button
              className="close"
              onClick={handleFechar}
              style={{
                background: `linear-gradient(135deg, ${data[0].response.cor_custom} 75%, ${data[0].response.cor_custom2} 100%)`,
              }}
            >
              <span>X</span>
            </button>
          </div>
        </Box>
      </Modal>
      <div className="div-space"></div>{' '}
    </div>
  );
}
