import { Voucher } from '../Voucher';
import './style.css';

export function Post() {
  return (
    <>
      <div className=" sixth-step">
        <section className="infos-post">
          <p>Vouchers em destaque</p>
        </section>

        <div className="img-post">
          <Voucher />
        </div>
      </div>
    </>
  );
}
