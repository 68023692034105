import React, { useState, useEffect } from "react";
import "./style.css";
import ButtonLink from "../../Components/ButtonLink/ButtonLink";
import { Header } from "../../Components/Header";
import { Layout } from "../../Components/Layout";
import { useParams } from "react-router-dom";
import Loading from "../../Components/Loading";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { GlobalContext } from "../../GlobalContext";
import { useTour } from "@reactour/tour";
import Cookies from "universal-cookie";
const { REACT_APP_URL_API } = process.env;

const style = {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--background)",
  border: "2px solid var(--title)",
  boxShadow: 24,
  p: 4,
};

const Home = () => {
  const { session, whoami } = React.useContext(GlobalContext);
  const [loading, setLoading] = useState(true);
  const { partner_id } = useParams();
  const [abrir1, setAbrir1] = React.useState(false);
  const handleAbrir1 = () => setAbrir1(true);
  const handleFechar1 = () => setAbrir1(false);
  const [data, setData] = React.useState(null);
  const alerta = localStorage.getItem("alerta");
  const alertBoolean = alerta == "true";

  const [abrir, setAbrir] = React.useState(alertBoolean);
  const handleAbrir = () => setAbrir(true);
  const handleFechar = () => {
    setAbrir(false);
    localStorage.removeItem("alerta");
  };

  const { cliente_id } = useParams();
  const { setIsOpen } = useTour();
  const teste = cliente_id;

  var raw = JSON.stringify({
    client_id: teste,
  });

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("csrftoken");

    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: "POST",
        credentials: "include",
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  }, []);

  if (data === null) return null;

  setTimeout(() => {
    setLoading(false);
  }, 800);
  {
    console.log(data);
  }
  if (loading)
    return (
      <div>
        <Loading />
      </div>
    );
  return (
    <>
      <Header />
      <Layout />

      {/* <button className="btn-tour" onClick={() => setIsOpen(true)}>
        Open Tour
      </button> */}
      {data[0]?.response.vantagens_vouchers === true && (
        <ButtonLink to={`/${cliente_id}/clube`} className="first-step">
          Todas as vantagens
        </ButtonLink>
      )}

      <span>
        {data[0]?.response.vantagens_sorteios === true && (
          <span>
            {whoami.isAuthenticated === false ? (
              <ButtonLink to="#" onClick={handleAbrir1}>
                Sorteios
              </ButtonLink>
            ) : (
              <ButtonLink className="second-step" to={`/${cliente_id}/sorteio`}>
                Sorteios
              </ButtonLink>
            )}
          </span>
        )}

        <span>
          {whoami.isAuthenticated === false ? (
            <ButtonLink to="#" onClick={handleAbrir1}>
              Mural
            </ButtonLink>
          ) : (
            <ButtonLink className="third-step" to={`/${cliente_id}/mural`}>
              Mural
            </ButtonLink>
          )}
        </span>

        {data[0]?.response.vantagens_eventos === true && (
          <span>
            {whoami.isAuthenticated === false ? (
              <ButtonLink to="#" onClick={handleAbrir1}>
                Eventos
              </ButtonLink>
            ) : (
              <ButtonLink className="fourth-step" to={`/${cliente_id}/eventos`}>
                Eventos
              </ButtonLink>
            )}
          </span>
        )}
      </span>

      <Modal
        open={abrir1}
        onClose={handleFechar1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p
              style={{
                color: "var(--title)",
                padding: "10px",
                marginBottom: "20px",
              }}
            >
              Você não está logado
            </p>
            <div>
              <ButtonLink to={`/${cliente_id}/auth`}> Fazer login</ButtonLink>
            </div>
            <button className="close" onClick={handleFechar1}>
              X
            </button>
          </div>
        </Box>
      </Modal>
      {whoami.isAuthenticated !== false ? (
        <Modal
          open={abrir}
          onClose={handleFechar}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div>
              <p
                style={{
                  color: "var(--title)",
                  padding: "10px",
                  marginBottom: "20px",
                }}
              >
                Deseja ver tutorial explicativo ?
              </p>

              <div>
                <ButtonLink
                  to="#"
                  className="btn-modal-alert"
                  onClick={() => {
                    handleFechar();
                    setIsOpen(true);
                  }}
                >
                  {" "}
                  Ver Tutorial
                </ButtonLink>
              </div>

              <button
                className="close"
                style={{ color: "#111", fontSize: "20px" }}
                onClick={handleFechar}
              >
                X
              </button>
            </div>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default Home;
