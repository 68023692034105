import React, { useState, useEffect } from "react";
import "./style.css";
import { IconContext } from "react-icons";
import {
  AiOutlineTwitter,
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
} from "react-icons/ai";
import { FaUserCircle } from "react-icons/fa";
import { GlobalContext } from "../../GlobalContext";
import { Link, useParams } from "react-router-dom";
import LogoCliente from "../LogoCliente";
import AvatarMenu from "../AvatarMenu";

const { REACT_APP_URL_API } = process.env;

export function Header() {
  const sair = () => {
    localStorage.removeItem("@user");

    var allCookies = document.cookie.split(";");

    window.location.reload();
  };

  const { session, whoami, item } = React.useContext(GlobalContext);

  let [social, setSocial] = React.useState(null);

  const { cliente_id } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setSocial(json));
  }, []);

  if (social === null) return null;
  return (
    <header className="header">
      <div className="container">
        <LogoCliente />

        <div className="menu-icons ">
          <IconContext.Provider value={{ size: "26px" }}>
            {social[0].response.social_media[0].instagram ? (
              <div>
                {" "}
                <a
                  href={social[0].response.social_media[0].instagram}
                  target="blank"
                >
                  <AiFillInstagram />
                </a>
              </div>
            ) : null}

            {social[0].response.social_media[0].youtube ? (
              <div>
                <a
                  href={social[0].response.social_media[0].youtube}
                  target="blank"
                >
                  <AiFillYoutube />
                </a>
              </div>
            ) : null}

            {social[0].response.social_media[0].facebook ? (
              <div>
                <a
                  href={social[0].response.social_media[0].facebook}
                  target="blank"
                >
                  <AiFillFacebook />
                </a>
              </div>
            ) : null}

            {social[0].response.social_media[0].twitter ? (
              <div>
                <a
                  href={social[0].response.social_media[0].twitter}
                  target="blank"
                >
                  <AiOutlineTwitter />
                </a>
              </div>
            ) : null}

            <div>
              {whoami.isAuthenticated !== false ? (
                <AvatarMenu />
              ) : (
                <Link to="auth">
                  {" "}
                  <FaUserCircle className="icon-login" />
                </Link>
              )}
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </header>
  );
}
