import React from 'react';

const Error = ({ error }) => {
  if (!error) return null;

  return (
    <div style={{ display: 'flex', position: 'relative', top: '-8px' }}>
      {' '}
      <span
        style={{
          color: '#f31',
          margin: '10px auto -30px',

          fontSize: '14px',
          position: 'relative',
          zIndex: 2,
        }}
      >
        {error}
      </span>
    </div>
  );
};

export default Error;
