import React, { useState, useEffect } from 'react';
import './style.css';
import { HeaderCardapio } from '../../../Components/HeaderCardapio';
import ContadorCardapio from '../../../Components/ContadorCardapio';
import Cookies from 'universal-cookie';
import { Link, useParams } from 'react-router-dom';

const { REACT_APP_URL_API } = process.env;

const CardapioItem = () => {
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();
  const teste = cliente_id;
  const [error, setError] = useState(null);
  var raw = JSON.stringify({
    client_id: teste,
  });
  const cookies = new Cookies();
  const token = cookies.get('csrftoken');

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });
  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);
  if (data === null) return null;
  const cor1 = data[0]?.response.cor_custom;
  const cor2 = data[0]?.response.cor_custom2;

  return (
    <div>
      <HeaderCardapio />

      <div className=" MainGrid">
        <div className="container-cardapio-item">
          <div className="container-img-text">
            <img
              src="https://icafebr.com.br/wp-content/uploads/2022/09/tipos-cafe-continental-mesa-diferenca-entre-pequeno-almoco-e-buffet-cafe-salgado-receita-rapida.jpg"
              alt=""
            />
            <h3>Café da manhã continental</h3>
            <p>
              Suco de laranja natural ou melancia, frutas frescas da estação (2
              tipos), 03 variedades de pães salgados e 02 variedades de pães
              doces, sortido de queijos e frios, manteiga, mel, geleia, café,
              leite integral ou desnatado, chá ou achocolatado.
            </p>
          </div>
          <div className=" div-text-opcionais">
            <h1> Escolha seu suco</h1>
            <p> Escolha até 1 opção</p>
            {/* <span>Obrigatório</span> */}
          </div>
          <hr />{' '}
          <div className="div-ocpionais">
            <p> Suco de Laranja</p>
            <input type="checkbox" />
          </div>
          <div className="div-ocpionais">
            <p>Suco de Laranja</p>
            <input type="checkbox" />
          </div>
          <div className="div-container-btn-cardapio">
            <div className="div-button-cardapio">
              <div>
                <ContadorCardapio />
              </div>

              <button className="btn-cardapio-add" style={{ background: cor1 }}>
                Adicionar: R$00,00
              </button>
            </div>
            <div className="div-btn-carrinho">
              <a
                href="carrinho"
                className="btn-ver-carrinho"
                style={{ background: cor1 }}
              >
                Ver Carrinho
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardapioItem;
