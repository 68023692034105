import React, { Component } from 'react';
import Loading from './Components/Loading';

const itemStyle = {
  flex: '1 0 auto',
  flexDirection: 'col',
  textAlign: 'center',
};

const countStyle = {
  fontSize: '4rem',
};

class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      loading: false,
    };
  }
  componentWillMount() {
    this.getTimeUntil(this.props.datetime);
  }
  componentDidMount() {
    setInterval(() => this.getTimeUntil(this.props.datetime), 1000);
  }
  leading0(num) {
    return num < 10 ? '0' + num : num;
  }

  getTimeUntil(datatime) {
    const time = Date.parse(datatime) - Date.parse(new Date());

    if (time < 0) {
      this.setState(
        { days: 0, hours: 0, minutes: 0, seconds: 0 },
        this.setState({ loading: true }),
        setTimeout(() => {
          this.setState({ loading: false });

          if (isNaN(datatime)) {
            return window.location.reload();
          }
          return window.location.reload();
        }, 2000),
      );
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      this.setState({ days, hours, minutes, seconds });
    }
  }

  render() {
    let content = this.state.loading ? <Loading /> : null;

    return (
      <div className="MainGrid cont-clock">
        <h2 style={{ color: 'var(--title)' }}>Início em:</h2>
        <div className="container-cronometro">
          <div className="relogio">
            <div className="countdown-days">
              <span id="day">{this.leading0(this.state.days)}</span>
              <span className="tempo">Dias</span>
            </div>

            <div>
              <span id="hour">{this.leading0(this.state.hours)} </span>
              <span className="tempo">Horas</span>
            </div>

            <div>
              <span id="minute">{this.leading0(this.state.minutes)}</span>
              <span className="tempo">minutos</span>{' '}
            </div>

            <div>
              <span id="second">{this.leading0(this.state.seconds)}</span>
              <span className="tempo">Segundos</span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Countdown;
