import './style.css';
import 'video-react/dist/video-react.css';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { useParams } from 'react-router-dom';
import Error from '../../Components/Error';
import Tooltip from '@mui/material/Tooltip';
import Help from '../../Components/Help';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Termos from '../../Components/Termos';
import { GlobalContext } from '../../GlobalContext';
import LogoCliente from '../../Components/LogoCliente';

// import Button from '@mui/material/Button';
// import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
// import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
// import DialogTitle from '@mui/material/DialogTitle';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { createTheme, useTheme } from '@mui/material/styles';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const { REACT_APP_URL_API } = process.env;

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Cadastro = () => {
  const { whoami } = React.useContext(GlobalContext);

  const { cliente_id, partner_id } = useParams();
  const cliente = cliente_id;
  const [cpf, setCep] = React.useState('');
  const [error, setError] = React.useState(null);
  const [err, setErr] = React.useState(null);
  const [mesage, setMesage] = React.useState('');

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  function validateCpf(value) {
    if (value.length === 0) {
      setError('Preencha um valor');
      return false;
    } else if (!/^\d{3}\.\d{3}\.\d{3}\-\d{2}$/.test(value)) {
      setError('Preencha um CPF válido');
      return false;
    } else {
      setError(null);
      return true;
    }
  }

  function handleBlur({ target }) {
    validateCpf(target.value);
  }

  function handleChange({ target }) {
    if (error) validateCpf(target.value);
    setCep(target.value);
  }

  const [client_id, setClient_id] = React.useState('');
  const [name, setName] = React.useState('');
  const [tax_number, setTax_number] = React.useState('');
  const [email, setEmail] = React.useState('');
  const [phone_number, setPhone_number] = React.useState('');
  const [birth_date, setBirth_date] = React.useState('');
  const [context, setContext] = React.useState([partner_id ? partner_id : '1']);
  const [password, setPassword] = React.useState('');
  const [confirm_password, setConfirm_password] = React.useState('');

  const [showPassword, setShowPassword] = React.useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [who, setWho] = React.useState(null);

  const [termos, setTermos] = React.useState(false);
  // const [open2, setOpen2] = React.useState(false);
  // const [color, setColor] = React.useState(null);
  // const [color2, setColor2] = React.useState(null);
  // const [send, setSend] = React.useState(false);

  // const theme = useTheme();

  // const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // const handleClickOpen = () => {
  //   setOpen2(true);
  // };

  // const handleClose2 = () => {
  //   setOpen2(false);
  //   setColor(null);
  //   setColor2(null);
  //   setSend(null);
  // };

  // const aceito = () => {
  //   setColor('#66bb6a');
  //   setColor2('#fff');
  //   setOpen2(false);
  //   setSend(true);
  // };

  const sendTermos = () => {
    setTermos(true);
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirm = () =>
    setShowPasswordConfirm((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleMouseDownPasswordConfirm = (event) => {
    event.preventDefault();
  };

  function handleSubmit(event) {
    event.preventDefault();

    // console.log({
    //   client_id,
    //   name,
    //   tax_number,
    //   email,
    //   phone_number,
    //   birth_date,
    //   context,
    //   password,
    //   confirm_password,
    // });

    fetch(`${REACT_APP_URL_API}/apiv2/signup/form/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name,
        tax_number,
        email,
        phone_number,
        birth_date,
        context,
        password,
        confirm_password,
        client_id: cliente,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        localStorage.setItem('alerta', true);
        localStorage.setItem('email', email);
        localStorage.setItem('password', password);
        window.location.replace('auth');
      })
      .catch((err) => setErr('E-mail já cadastrado'));
  }

  let [data, setData] = React.useState(null);
  let [cor, setCor] = React.useState(null);

  var raw = JSON.stringify({
    client_id: cliente,
  });

  const beneficiario_id = partner_id ? partner_id : false;

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setCor(json));
  }, []);

  useEffect(() => {
    window

      .fetch(`${REACT_APP_URL_API}/apiv2/signup/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          client_id: cliente,
          beneficiario_id: beneficiario_id,
        }),
      })
      .then((response) => response.json())
      .then((json) => setData(json[2].context));
  }, []);

  if (data === null) return null;
  if (cor === null) return null;
  return (
    <div>
      <header className="header-login">
        <div>
          <LogoCliente />
        </div>
      </header>
      <h1 className="title-login">Cadastro</h1>
      <form className="form-login-container" onSubmit={handleSubmit}>
        <div className="input-cadastro">
          <OutlinedInput
            type="text"
            placeholder="Nome"
            value={name}
            required
            onChange={({ target }) => setName(target.value)}
          />
        </div>

        <div className="input-cadastro cpf-input">
          <InputMask
            mask="999.999.999-99"
            type="text"
            placeholder="Cpf"
            onBlur={handleBlur}
            value={tax_number}
            required
            onChange={({ target }) => setTax_number(target.value)}
          />

          {error && <Error error={error} />}
        </div>

        <div className="input-cadastro">
          <Tooltip title="Caso seja apoiador, utilizar o mesmo endereço de e-mail. ">
            <OutlinedInput
              type="email"
              placeholder="Email"
              value={email}
              required
              onChange={({ target }) => setEmail(target.value)}
            />
          </Tooltip>
          {err && <Error error={err} />}
        </div>

        <div className="input-cadastro">
          <InputMask
            mask="(99)99999-9999"
            type="text"
            placeholder="Celular"
            value={phone_number}
            required
            onChange={({ target }) => setPhone_number(target.value)}
          />
        </div>

        <div className="input-cadastro">
          <InputMask
            mask="99/99/9999"
            type="text"
            placeholder="Data de nascimento"
            value={birth_date}
            required
            onChange={({ target }) => setBirth_date(target.value)}
          />
        </div>

        {cliente_id === 'RD_Pardo003' ? (
          <div className="input-cadastro">
            <select
              name="empresas"
              value={context}
              onChange={({ target }) => setContext(target.value)}
              style={{ color: 'var(--title)' }}
              required
            >
              <option value="teste" selected>
                Selecione uma ocupação
              </option>
              {data.map((item) => (
                <option key={item.id} value={item.id} required>
                  {item.nome}
                </option>
              ))}
            </select>
          </div>
        ) : (
          <div className="input-cadastro" style={{ display: 'none' }}>
            <select
              name="empresas"
              value={context}
              onChange={({ target }) => setContext(target.value)}
              style={{ color: 'var(--title)' }}
              required
            >
              <option value="teste" selected>
                Selecione uma ocupação
              </option>
              {data.map((item) => (
                <option key={item.id} value={item.id} required>
                  {item.nome}
                </option>
              ))}
            </select>
          </div>
        )}

        <div className="input-cadastro">
          <Tooltip title="Senha deve conter no mínimo 8 caracteres, letras e números">
            <OutlinedInput
              placeholder="Senha"
              value={password}
              required
              onChange={({ target }) => setPassword(target.value)}
              id="outlined-adornment-password"
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Tooltip>
        </div>
        <div className="input-cadastro">
          <OutlinedInput
            placeholder="Confirmar senha"
            value={confirm_password}
            required
            onChange={({ target }) => setConfirm_password(target.value)}
            id="outlined-adornment-password"
            type={showPasswordConfirm ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPasswordConfirm}
                  onMouseDown={handleMouseDownPasswordConfirm}
                  edge="end"
                >
                  {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {/* {confirm_password && showPassword === false && (
            <Error error="Senhas não conferem" />
          )} */}
        </div>

        {/* <div className="cont-termos">
          <input className="termos" type="checkbox" />{' '}
          <span className="text-termos">
            {' '}
            Aceito receber notificações de novos beneficios
          </span>
        </div> */}

        <div className="cont-termos">
          <input
            className="termos"
            type="checkbox"
            onChange={sendTermos}
            required
          />{' '}
          <span className="text-termos">
            <Termos />
          </span>
        </div>
        <div className="button-cadastro btn-submit">
          {termos === true ? (
            <button
              type="submit"
              className="btn-enviar"
              style={{
                background: `linear-gradient(135deg, ${cor[0].response.cor_custom} 75%, ${cor[0].response.cor_custom2} 100%)`,
              }}
            >
              Enviar
            </button>
          ) : (
            <button
              type="submit"
              className="btn-enviar"
              disabled
              style={{
                background: `linear-gradient(135deg, ${cor[0].response.cor_custom} 75%, ${cor[0].response.cor_custom2} 100%)`,
                opacity: '.7',
              }}
            >
              Enviar
            </button>
          )}
        </div>

        {/* <div>
          <div className="div-btn-termos">
            {' '}
            <Button
              onClick={handleClickOpen}
              className="btn-termos"
              style={{
                background: color,
                color: color2,
                border: '1px solid var(--border)',
              }}
              endIcon={<CheckCircleIcon style={{ color: ' #fff' }} />}
              required
            >
              Termo e condições{' '}
            </Button>
          </div>

          <Dialog
            fullScreen={fullScreen}
            open={open2}
            onClose={handleClose2}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              <p style={{ color: 'var(--secondary)' }}>Termos e condições </p>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <p style={{ color: 'var(--secondary)' }}> termos text</p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose2}>
                Discordo
              </Button>
              <Button onClick={aceito} autoFocus>
                Concordo
              </Button>
            </DialogActions>
          </Dialog>
        </div> */}
      </form>

      <Help />
    </div>
  );
};

export default Cadastro;
