import React, { useState, useEffect } from 'react';
import './style.css';
import { HeaderCardapio } from '../../../Components/HeaderCardapio';
import ContadorCarrinho from '../../../Components/ContadorCarrinho';
import Cookies from 'universal-cookie';
import { Link, useParams } from 'react-router-dom';

const { REACT_APP_URL_API } = process.env;
const Carrinho = () => {
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();
  const teste = cliente_id;
  const [error, setError] = useState(null);
  var raw = JSON.stringify({
    client_id: teste,
  });
  const cookies = new Cookies();
  const token = cookies.get('csrftoken');

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });
  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);
  if (data === null) return null;
  const cor1 = data[0]?.response.cor_custom;
  const cor2 = data[0]?.response.cor_custom2;
  return (
    <div>
      <HeaderCardapio />

      <div className=" MainGrid">
        {' '}
        <div className="container_checkout">
          <h1>ITENS NO CARRINHO</h1>
          <hr className="linha_carrinho" />
          <div className="div_itens_carrinho">
            <p>Café continental</p>
            <ContadorCarrinho />
            <p>R$29,90</p>
          </div>

          {/* item 2 */}
          <hr className="linha_carrinho" />
          <div className="div_itens_carrinho">
            <p>Agua com gás</p>
            <ContadorCarrinho />
            <p>R$10,90</p>
          </div>

          {/* item 3 */}
          <hr className="linha_carrinho" />
          <div className="div_itens_carrinho">
            <p>Cafe continental</p>
            <ContadorCarrinho />
            <p>R$99,90</p>
          </div>
        </div>
        <div className="container_checkout" style={{ marginTop: '40px' }}>
          <h1>DETALHES</h1>
          <hr className="linha_carrinho" />
          <div className="div_itens_carrinho">
            <p>Total de itens</p>
            <p>R$29,90</p>
          </div>
          <hr className="linha_carrinho" />

          <div className="div_itens_carrinho">
            <p>
              <b>Valor Total</b>
            </p>
            <p>
              <b>R$129,80</b>
            </p>
          </div>
        </div>
        <div className="div-btn-carrinho">
          <button className="btn-finalizar-pedido" style={{ background: cor1 }}>
            Finalizar Pedido{' '}
          </button>
        </div>{' '}
      </div>
    </div>
  );
};

export default Carrinho;
