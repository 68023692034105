import React, { useEffect, useState } from 'react';
import './style.css';
import 'video-react/dist/video-react.css';
import Modal from '@mui/material/Modal';
import { Player } from 'video-react';
import VideoTutorial from '../../Assets/video.mp4';
import { useParams } from 'react-router-dom';
const { REACT_APP_URL_API } = process.env;

const Help = () => {
  const { cliente_id } = useParams();
  const cliente = cliente_id;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  let [cor, setCor] = React.useState(null);
  var raw = JSON.stringify({
    client_id: cliente,
  });
  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setCor(json));
  }, []);

  if (cor === null) return null;

  return (
    <div>
      <div
        className="div-totorial"
        style={{
          background: `linear-gradient(135deg, ${cor[0].response.cor_custom} 75%, ${cor[0].response.cor_custom2} 100%)`,
        }}
        onClick={handleOpen}
      >
        <span>?</span>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="div-player">
          <button
            className="btn-fechar-video"
            style={{
              background: `linear-gradient(135deg, ${cor[0].response.cor_custom} 75%, ${cor[0].response.cor_custom2} 100%)`,
            }}
            onClick={handleClose}
          >
            X
          </button>
          <Player poster="/assets/poster.png" autoPlay={true}>
            <source src={VideoTutorial} />
          </Player>
        </div>
      </Modal>
    </div>
  );
};

export default Help;
