import React, { useEffect, useState } from 'react';
import './style.css';
import { Link, useParams } from 'react-router-dom';
const { REACT_APP_URL_API } = process.env;
const LogoClienteFacilities = () => {
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();
  const { partner_id } = useParams();
  const cliente = cliente_id;
  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);

  if (data === null) return null;

  return (
    <div className="container">
      {data.map((item, index) => (
        <Link
          to={`/${cliente_id}/${partner_id ? partner_id : ''}/facilities`}
          key={index}
        >
          {' '}
          <img
            className="logo"
            src={`${REACT_APP_URL_API}` + item.response.logo}
          />
        </Link>
      ))}
    </div>
  );
};

export default LogoClienteFacilities;
