import './style.css';
import React, { useEffect, useState } from 'react';
import imageToBase64 from 'image-to-base64/browser';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../GlobalContext';
import CircularProgress from '@mui/material/CircularProgress';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { AiFillCamera } from 'react-icons/ai';
import ImageInput from '../../Screens/Demo/file/ImageInput';
import Cookies from 'universal-cookie';

const { REACT_APP_URL_API } = process.env;

const AvatarFoto = () => {
  const { item } = React.useContext(GlobalContext);
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);

  const [imgBase, setImgBase] = useState();
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState('');
  let [perfil, setPerfil] = React.useState(null);

  useEffect(() => {
    if (image.photo?.src) {
      imageToBase64(image.photo.src)
        .then((response) => setImgBase(response))
        .catch((error) => {
          console.log(error); // Logs an error if there was one
        });
    }
  }, [image]);

  const img64 = `data:image/png;base64,${imgBase}`;

  var raw2 = JSON.stringify({
    client_id: cliente,
    img: img64,
  });

  const sendImg = (e) => {
    setLoading(true);
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/perfil/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw2,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setPerfil(json);

        setTimeout(() => {
          setLoading(false);
          window.location.reload();
        }, 500);
      });
  };

  const editProfile = localStorage.getItem('edit');

  const editSend = (e) => {
    localStorage.setItem('edit', false);
  };

  if (data === null) return null;

  return (
    <div>
      {loading ? (
        <div>
          <Avatar
            sx={{ width: 150, height: 150 }}
            style={{
              background: `linear-gradient(135deg, ${data[0].response.cor_custom} 75%, ${data[0].response.cor_custom2} 100%)`,
              fontSize: '70px',
            }}
          >
            <div sx={{ display: 'flex' }}>
              <CircularProgress style={{ color: 'var(--background)' }} />
            </div>
          </Avatar>
        </div>
      ) : (
        <div>
          <Stack direction="row" spacing={2}>
            <label htmlFor="id-input" className="foto-label" tabIndex="0">
              <Avatar
                src={image.photo?.src || `${REACT_APP_URL_API}${item.img_path}`}
                sx={{ width: 150, height: 150 }}
                style={{
                  background: `linear-gradient(135deg, ${data[0].response.cor_custom} 75%, ${data[0].response.cor_custom2} 100%)`,
                  fontSize: '70px',
                  border: `2px solid ${data[0].response.cor_custom}`,
                }}
              >
                <p style={{ color: '#fff', marginLeft: '30px' }}>
                  {item.full_name[0].toUpperCase()}
                </p>{' '}
                <AiFillCamera />
              </Avatar>
              <div className="icon-add">
                {image.photo?.src || item.img_path ? (
                  <AddCircleIcon
                    style={{
                      color: `${data[0].response.cor_custom}`,
                      background: 'var(--background)',
                      borderRadius: '50%',
                    }}
                  />
                ) : null}
              </div>
              <ImageInput
                type="file"
                id="id-input"
                name="photo"
                label="Add Photo"
                className="image-input"
                showPreview
                onChange={(files) => setImage(files, 'admin')}
              />
            </label>
          </Stack>
          <div>
            {editProfile === 'true' ? (
              <div className="div-btn-edit">
                <button
                  onClick={() => {
                    sendImg();
                    editSend();
                  }}
                >
                  SALVAR FOTO
                </button>
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default AvatarFoto;
