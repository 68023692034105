import './style.css';
import ReactGA from 'react-ga';
import { duration, Modal } from '@mui/material';
import Stories from 'react-insta-stories';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { GlobalContext } from '../../GlobalContext';
import img1 from '../../Assets/1.png';

const { REACT_APP_URL_API } = process.env;

const stories = [
  {
    url: 'https://images.pexels.com/photos/3494373/pexels-photo-3494373.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260',
    duration: 2000,
    header: {
      heading: 'Cusco Baudoso',
      profileImage:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVLakRiNJ_pidUtJfw3I4mLRkMtbY_gujv1t1Jm5A-cA&s',
    },
  },

  {
    url: 'https://s2.best-wallpaper.net/wallpaper/iphone/2109/Blue-sea-coast-sailboat-boat-trees-rocks_iphone_1080x1920.jpg',
    duration: 3000,
    header: {
      heading: 'Cusco Baudoso',
      profileImage:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVLakRiNJ_pidUtJfw3I4mLRkMtbY_gujv1t1Jm5A-cA&s',
    },
  },
  {
    url: 'https://player.vimeo.com/external/420568727.sd.mp4?s=bd4d6b75a5a1c960a222d3c9923574f70d122e0c&profile_id=165&oauth2_token_id=57447761',
    duration: 3000,

    type: 'video',

    header: {
      heading: 'Cusco Baudoso',
      profileImage:
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVLakRiNJ_pidUtJfw3I4mLRkMtbY_gujv1t1Jm5A-cA&s',
    },
  },
];

const stories2 = [
  {
    url: 'https://uploaddeimagens.com.br/images/004/425/911/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto_%283%29.png?1681231841',
    duration: 2000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },

  {
    url: 'https://uploaddeimagens.com.br/images/004/425/908/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto.png?1681231744',
    duration: 3000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },
  {
    url: 'https://uploaddeimagens.com.br/images/004/425/915/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto_%282%29.png?1681231884',
    duration: 3000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },
  {
    url: 'https://uploaddeimagens.com.br/images/004/425/916/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto_%281%29.png?1681231926',
    duration: 3000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },

  {
    url: 'https://uploaddeimagens.com.br/images/004/425/924/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto_%284%29.png?1681232556',
    duration: 3000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },

  {
    url: 'https://uploaddeimagens.com.br/images/004/425/930/full/Access_Point_Wireless_Dual_Band_AX3600_Monta%CC%81vel_em_Teto_%285%29.png?1681232859',
    duration: 3000,
    header: {
      heading: 'Tp Link',
      profileImage:
        'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
    },
  },

  // {
  //   url: 'https://vimeo.com/user136640925',
  //   duration: 3000,

  //   type: 'video',

  //   header: {
  //     heading: 'Tp Link',
  //     profileImage:
  //       'https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png',
  //   },
  // },
];

export function Story({ story, action }) {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleOpen = (category, action, label) => {
    setOpen(true);
    console.log('GA event:', category, ':', action, ':', label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  const handleOpen2 = (category, action, label) => {
    setOpen2(true);
    console.log('GA event:', category, ':', action, ':', label);
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  };

  const handleClose = () => setOpen(false);
  const handleClose2 = () => setOpen2(false);

  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);

  const { item } = React.useContext(GlobalContext);

  console.log('item', item.username);

  if (data === null) return null;

  return (
    <div className="container">
      <div className="user-elements">
        <div
          style={{
            background: `linear-gradient(  ${data[0].response.cor_custom}  , ${data[0].response.cor_custom2}  )`,
          }}
        >
          <img
            className="image-user-story"
            src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRVLakRiNJ_pidUtJfw3I4mLRkMtbY_gujv1t1Jm5A-cA&s"
            onClick={handleOpen.bind(
              this,
              'Story',
              `${item.full_name}, Abriu Story`,
              'Cusco Baudoso',
            )}
          />
        </div>
        <span>Cusco B.</span>
      </div>

      <div className="user-elements">
        <div
          style={{
            background: `linear-gradient(  ${data[0].response.cor_custom}  , ${data[0].response.cor_custom2}  )`,
          }}
        >
          <img
            className="image-user-story"
            src="https://www.baixesoft.com/wp-content/uploads/2021/03/TP-link-icone.png"
            onClick={handleOpen2.bind(
              this,
              'Story',
              `${item.full_name}, Abriu Story`,
              'Tp Link',
            )}
          />
        </div>
        <span>Tp Link</span>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: '2px' }}
      >
        <div className="div-story">
          <button className="btn-fechar-story" onClick={handleClose}>
            X
          </button>
          <Stories stories={stories} defaultInterval={1500} />
        </div>
      </Modal>

      <Modal
        open={open2}
        onClose={handleClose2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ marginTop: '2px' }}
      >
        <div className="div-story">
          <button className="btn-fechar-story" onClick={handleClose2}>
            X
          </button>
          <Stories stories={stories2} defaultInterval={1500} />
        </div>
      </Modal>

      {/* <div className="user-elements">
        <div>
          <img
            className="image-user-story"
            src="https://windcharter.com.br/pt/images/logo.jpg"
          />
        </div>
        <span>WindCh</span>
      </div>

      <div className="user-elements">
        <div>
          <img
            className="image-user-story"
            src="https://cdn.iset.io/assets/61568/imagens/logo-velamar.png"
          />
        </div>
        <span>Velamar</span>
      </div>

      <div className="user-elements">
        <div>
          <img
            className="image-user-story"
            src="https://site.netunoapp.com.br/wp-content/uploads/2021/12/netunoLogoFullV2-1-208x300.png"
          />
        </div>
        <span>Netuno</span>
      </div> */}
    </div>
  );
}
