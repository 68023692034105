import './style.css';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import ReactLoading from 'react-loading';
const { REACT_APP_URL_API } = process.env;

const Loading = () => {
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);

  if (data === null) return null;

  return (
    // <div className="container-loading">
    //   <div class="wrapper">
    //     <div
    //       class="circle"
    //       style={{
    //         background: `linear-gradient(135deg, ${data[0].response.cor_custom} 75%, ${data[0].response.cor_custom2} 100%)`,
    //       }}
    //     ></div>
    //     <div
    //       class="circle"
    //       style={{
    //         background: `linear-gradient(135deg, ${data[0].response.cor_custom} 75%, ${data[0].response.cor_custom2} 100%)`,
    //       }}
    //     ></div>
    //     <div
    //       class="circle"
    //       style={{
    //         background: `linear-gradient(135deg, ${data[0].response.cor_custom} 75%, ${data[0].response.cor_custom2} 100%)`,
    //       }}
    //     ></div>
    //     <div class="shadow"></div>
    //     <div class="shadow"></div>
    //     <div class="shadow"></div>
    //     <span
    //       style={{
    //         color: data[0].response.cor_custom,
    //       }}
    //     >
    //       Carregando
    //     </span>
    //   </div>
    // </div>

    <div className="container-loading">
      <ReactLoading
        type="spin"
        color={data[0].response.cor_custom}
        height={'5%'}
        width={'5%'}
        className="loading"
      />
    </div>
  );
};

export default Loading;
