import React, { useState } from 'react';
import './index.css';
import imageCompression from 'browser-image-compression';
import ImageModal from '../cropper/ImageModal';

const ImageInput = ({ name, onChange, showPreview, imageData, defaultPic }) => {
  const [image, setImage] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [height, setHeight] = useState(null);
  const [width, setWidth] = useState(null);

  const onChangeHandler = (file) => {
    onChange({
      [name]: {
        data: file[0],
        src: URL.createObjectURL(file[0]),
      },
    });
  };

  const handleFile = async (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const options = {
        maxSizeMB: 1, // Tamanho máximo do arquivo em MB
        maxWidthOrHeight: 1920, // Largura ou altura máxima
        useWebWorker: true, // Usar web worker para compressão
      };

      try {
        const compressedFile = await imageCompression(file, options);
        const url = URL.createObjectURL(compressedFile);
        var img = new Image();
        img.src = url;

        img.onload = function () {
          setWidth(this.width);
          setHeight(this.height);
        };

        setImage(compressedFile);
        setModalIsOpen(true);

        e.target.value = null;
      } catch (error) {
        console.error('Erro ao comprimir a imagem:', error);
      }
    }
  };

  let inputElement;

  return (
    <>
      <ImageModal
        modalIsOpen={modalIsOpen}
        closeModal={() => {
          setModalIsOpen((prevState) => !prevState);
        }}
        image={image}
        onCropImage={(croppedImg) => onChangeHandler([croppedImg])}
        ratio={height / width <= 0.5 ? true : false}
      />

      <div
        className={'upload-image'}
        onClick={() => inputElement.click()}
      ></div>
      <input
        ref={(input) => (inputElement = input)}
        id="id-input"
        type="file"
        style={{ display: 'none' }}
        onChange={handleFile}
        className="image-input"
      />
    </>
  );
};

export default ImageInput;
