import React, { useState, useEffect } from 'react';
import './style.css';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { HeaderFacilities } from '../../../Components/HeaderFacilities';
import { Link } from 'react-scroll';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
const { REACT_APP_URL_API } = process.env;
const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};
const Diretorio = () => {
  let [data, setData] = React.useState(null);
  const [value, setValue] = React.useState(0);
  const { cliente_id } = useParams();
  const teste = cliente_id;
  const [error, setError] = useState(null);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  var raw = JSON.stringify({
    client_id: teste,
  });
  const cookies = new Cookies();
  const token = cookies.get('csrftoken');

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });
  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);
  if (data === null) return null;
  const cor1 = data[0]?.response.cor_custom;
  const cor2 = data[0]?.response.cor_custom2;

  return (
    <div>
      <HeaderFacilities />

      <Box
        sx={{
          maxWidth: '930px',
          margin: '120px auto',
          left: '1%',
          right: '1%',
          bgcolor: 'var(--background-main)',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={true}
          aria-label="scrollable prevent tabs example"
        >
          <Tab label="Serviços" style={{ color: 'var(--secondary)' }} />

          <Tab label="Canais de TV" style={{ color: 'var(--secondary)' }} />
        </Tabs>

        {value === 0 && (
          <div className="MainGrid" style={{ marginBottom: '-60px' }}>
            <div className="div-container-diretorio-servicos">
              <div className="categorias categorias-cardapio">
                <ul style={{ marginTop: '90px' }}>
                  <li>
                    <Link
                      className="cafe"
                      to="cafe"
                      spy="cafe"
                      smooth={true}
                      duration={500}
                      offset={-99}
                      activeClass="ActiveLink"
                    >
                      Entretenimento{' '}
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="almoco"
                      to="almoco"
                      spy="almoco"
                      smooth={true}
                      duration={500}
                      offset={-99}
                      activeClass="ActiveLink"
                    >
                      Instalações
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="servico"
                      to="servico"
                      spy="servico"
                      smooth={true}
                      duration={500}
                      offset={-99}
                      activeClass="ActiveLink"
                    >
                      Servicos
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="lavanderia"
                      to="lavanderia"
                      spy="lavanderia"
                      smooth={true}
                      duration={500}
                      offset={-99}
                      activeClass="ActiveLink"
                    >
                      Lavanderia
                    </Link>
                  </li>
                </ul>
              </div>

              <Box
                sx={{
                  maxWidth: '930px',
                  margin: '170px auto',
                  left: '1%',
                  right: '1%',
                  bgcolor: 'var(--background-main)',
                }}
              >
                <div className="cafe" name="cafe" id="cafe">
                  <div className="container-clube-voucher">
                    <h2
                      style={{
                        color: 'var(--secondary)',
                        fontSize: '20px',
                        fontWeight: 300,
                        padding: '0px 0px 10px 10px',
                      }}
                    >
                      Entretenimento
                    </h2>

                    <div>
                      <div className="box container-card-cardapio">
                        <div className="servicos-container">
                          <div>
                            <img
                              src="https://d2r9epyceweg5n.cloudfront.net/stores/001/912/178/rte/Play%20do%20Matheus%20001.jpg"
                              alt=""
                            />
                          </div>

                          <div className="counteudo-servicos">
                            <p>
                              <b>Brinquedoteca</b>
                            </p>
                            <p>
                              Traga seu filho para se divertir em nossa
                              brinquedoteca.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* item 2 */}

                    <div>
                      <div className="box container-card-cardapio">
                        <div className="servicos-container">
                          <div>
                            <img
                              src="https://classic.exame.com/wp-content/uploads/2021/11/MARCAS-1.jpg?quality=70&strip=info&w=1024"
                              alt=""
                            />
                          </div>

                          <div className="counteudo-servicos">
                            <p>
                              <b>Restaurante</b>
                            </p>
                            <p>
                              Além do cardápio com toque brasileiro, o
                              restaurante do hotel possui um calendário de
                              programações para todos os gostos e estilos. Todas
                              as sextas e sábados a partir das 19h, oferece
                              happy hour com música ao vivo, DJ´s e menu com
                              comida de boteco.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="almoco" name="almoco" id="almoco">
                  <div className="container-clube-voucher">
                    <h2
                      style={{
                        color: 'var(--secondary)',
                        fontSize: '20px',
                        fontWeight: 300,
                        padding: '0px 0px 10px 10px',
                      }}
                    >
                      Instalações
                    </h2>

                    <div>
                      <div className="box container-card-cardapio">
                        <div className="servicos-container">
                          <div>
                            <img
                              src="https://dnd1g0gk41u1l.cloudfront.net/image/filename/3989243/x_lg_IIs4yj4yef3Tcq9amblEpSkqG460GTNn.jpg"
                              alt=""
                            />
                          </div>

                          <div className="counteudo-servicos">
                            <p>
                              <b>Academia</b>
                            </p>
                            <p>
                              Academia equipada com os melhores equipamentos.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="box container-card-cardapio">
                        <div className="servicos-container">
                          <div>
                            <img
                              src="https://www.hotelfazendaroseira.com.br/wp/wp-content/uploads/2019/06/piscina-com-bar-molhado-hotel-fazenda-roseira-brotas.jpg"
                              alt=""
                            />
                          </div>

                          <div className="counteudo-servicos">
                            <p>
                              <b>Piscina</b>
                            </p>
                            <p>Piscina com vista deslumbrante</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="servico" name="servico" id="servico">
                  <div className="container-clube-voucher">
                    <h2
                      style={{
                        color: 'var(--secondary)',
                        fontSize: '20px',
                        fontWeight: 300,
                        padding: '0px 0px 10px 10px',
                      }}
                    >
                      Serviços
                    </h2>

                    <div>
                      <div className="box container-card-cardapio">
                        <div className="servicos-container">
                          <div>
                            <img
                              src="https://www.baressp.com.br/bares/fotos2/must-bar-baressp-min.jpg"
                              alt=""
                            />
                          </div>

                          <div className="counteudo-servicos">
                            <p>
                              <b>Bar</b>
                            </p>
                            <p>
                              Serviço de Bar com Horário de Funcionamento: 24
                              Horas
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="box container-card-cardapio">
                        <div className="servicos-container">
                          <div>
                            <img
                              src="https://content3.jdmagicbox.com/comp/ahmednagar/t9/9999px241.x241.150516125650.w2t9/catalogue/hotel-mejwani-pure-veg-and-fast-food-restaurant-savedi-ahmednagar-restaurants-3khcpfu.jpg"
                              alt=""
                            />
                          </div>

                          <div className="counteudo-servicos">
                            <p>
                              <b>Gastronomia</b>
                            </p>
                            <p>Gastronomy Fresh Food</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="lavanderia" name="lavanderia" id="lavanderia">
                  <div className="container-clube-voucher">
                    <h2
                      style={{
                        color: 'var(--secondary)',
                        fontSize: '20px',
                        fontWeight: 300,
                        padding: '0px 0px 10px 10px',
                      }}
                    >
                      Lavanderia
                    </h2>

                    <div>
                      <div className="box container-card-cardapio">
                        <div className="servicos-container">
                          <div>
                            <img
                              src="https://turismo.uai.com.br/wp-content/uploads/2023/10/Captura-de-tela-2023-10-09-125140.jpg"
                              alt=""
                            />
                          </div>

                          <div className="counteudo-servicos">
                            <p>
                              <b>Roupa pesada</b>
                            </p>
                            <p>Lavagem de roupa pesasda: R$ 17,00</p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div className="box container-card-cardapio">
                        <div className="servicos-container">
                          <div>
                            <img
                              src="https://multitherm.com.br/wp-content/uploads/2017/09/Lavanderia-hotel-1585X800.jpg"
                              alt=""
                            />
                          </div>

                          <div className="counteudo-servicos">
                            <p>
                              <b>Roupa leve</b>
                            </p>
                            <p>
                              Lavagem de roupas leves R$ 15,00 Horário de
                              Funcionamento: 24 Horas
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div style={{ height: '100px' }}></div>
                  </div>
                </div>
              </Box>
            </div>
          </div>
        )}

        {value === 1 && (
          <div className="MainGrid" style={{ marginBottom: '-60px' }}>
            {' '}
            <div
              className="box"
              style={{
                margin: '10px auto',
                border: 'none',
                background: 'var(--background-main)',
              }}
            >
              <div className="div-title-canais-tv">
                {' '}
                <h1>Canais de Tv</h1>
              </div>

              <div className="container-card-tv">
                <div className="div-tv-card box">
                  <div
                    className="div-numero-canal"
                    style={{ background: cor1 }}
                  >
                    <p>2.1</p>
                  </div>
                  <div className="div-nome-canal">
                    <p>Cultura | R$ PAGO</p>
                  </div>
                </div>
                <div className="div-tv-card box">
                  <div
                    className="div-numero-canal"
                    style={{ background: cor1 }}
                  >
                    <p>4.1</p>
                  </div>
                  <div className="div-nome-canal">
                    <p>SBT</p>
                  </div>
                </div>
                <div className="div-tv-card box">
                  <div
                    className="div-numero-canal"
                    style={{ background: cor1 }}
                  >
                    <p>5.1</p>
                  </div>
                  <div className="div-nome-canal">
                    <p>Globo</p>
                  </div>
                </div>
                <div className="div-tv-card box">
                  <div
                    className="div-numero-canal"
                    style={{ background: cor1 }}
                  >
                    <p>7.1</p>
                  </div>
                  <div className="div-nome-canal">
                    <p>Record</p>
                  </div>
                </div>
                <div className="div-tv-card box">
                  <div
                    className="div-numero-canal"
                    style={{ background: cor1 }}
                  >
                    <p>13.1</p>
                  </div>
                  <div className="div-nome-canal">
                    <p>Band</p>
                  </div>
                </div>{' '}
                <div className="div-tv-card box">
                  <div
                    className="div-numero-canal"
                    style={{ background: cor1 }}
                  >
                    <p>32</p>
                  </div>
                  <div className="div-nome-canal">
                    <p>MTV</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Box>
    </div>
  );
};

export default Diretorio;
