import React, { useState, useEffect } from 'react';
import './style.css';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';

const { REACT_APP_URL_API } = process.env;

const Slot = () => {
  let [sorteio, setSorteio] = React.useState([]);
  const { cliente_id } = useParams();
  const { id } = useParams();

  const teste = cliente_id;
  var raw2 = JSON.stringify({
    client_id: teste,
    sorteio_id: id,
  });

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio_screen_controller/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw2,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setSorteio(json);
      });
  }, []);
  if (sorteio === null) return null;

  const num = sorteio[0]?.names_list.length - 1;

  console.log('length', num);

  const vencedor = sorteio[0]?.chosen_name;
  localStorage.setItem('vencedor', `${vencedor}`);

  return (
    <>
      <div class="base">
        <div class="letter-one">
          {sorteio[0]?.names_list ? (
            <table>
              {sorteio[0]?.names_list.map((item, index) => (
                <tr key={index} style={{ '--ch': `${num}` }}>
                  {item ? <td>{item}</td> : <td>Sem participantes</td>}
                </tr>
              ))}
            </table>
          ) : (
            <p>Carregando...</p>
          )}
        </div>
      </div>
    </>
  );
};
export default Slot;
