import React from "react";
import "./style.css";
import HeaderCommunity from "../../../Components/HeaderCommunity";
import ProgressBar from "react-bootstrap/ProgressBar";
import "bootstrap/dist/css/bootstrap.min.css";
import { NavLink } from "react-router-dom";

const Classroom = () => {
  const now = 64;

  return (
    <div>
      <HeaderCommunity />
      <div className="container__class">
        <div className="container-class-geral">
          <div className="container_class">
            <div className="container__conteudo_class">
              <div className="div__conteudo_class">
                <div className="card__conteudo">
                  <NavLink
                    to={"/RD_Pardo003/classroom/id"}
                    className="link__card_class"
                  >
                    <div
                      className="img__class"
                      style={{
                        backgroundImage:
                          "url(https://assets.skool.com/f/c549a31b7ff2472da792c67915aacff3/158327157e464f719ed4f26c5ea97bd0ca87545e55ff4ae190d96ae4fc446dfc)",
                      }}
                    ></div>
                    <div className="text__class">
                      <h3>Como usar a comunidade Skool</h3>
                      <p>
                        Isso mostrará como você pode usar a comunidade skool ao
                        máximo.
                      </p>
                      <ProgressBar now={now} label={`${now}%`} />

                      <button
                        className="btn__abrir_class"
                        style={{ width: "100%" }}
                      >
                        Abrir
                      </button>
                    </div>
                  </NavLink>
                </div>

                <div className="card__conteudo">
                  <NavLink
                    to={"/RD_Pardo003/classroom/id"}
                    className="link__card_class"
                  >
                    <div
                      className="img__class"
                      style={{
                        backgroundImage:
                          "url(https://assets.skool.com/f/c549a31b7ff2472da792c67915aacff3/d73d412f7d7a41a0b6ac64ad34df8555881ef4bea5c0490d854822312a7467d1)",
                      }}
                    ></div>
                    <div className="text__class">
                      <h3>Webinar sobre domínio de automação de IA</h3>
                      <p>
                        Isso mostrará como você pode usar dominar inteligencia
                        artificial.
                      </p>
                      <ProgressBar now={now} label={`${now}%`} />

                      <button
                        className="btn__abrir_class"
                        style={{ width: "100%" }}
                      >
                        Abrir
                      </button>
                    </div>
                  </NavLink>
                </div>

                <div className="card__conteudo">
                  <NavLink
                    to={"/RD_Pardo003/classroom/id"}
                    className="link__card_class"
                  >
                    <div
                      className="img__class"
                      style={{
                        backgroundImage:
                          "url(https://i.ytimg.com/vi/gMxQ8vxH9Vk/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLAVQ-I7qwCZ0oNpZd5ftbWo-ihsng)",
                      }}
                    ></div>
                    <div className="text__class">
                      <h3>lógica de programação</h3>
                      <p>
                        Isso mostrará como você pode usar lógica de programação.
                      </p>
                      <ProgressBar now={now} label={`${now}%`} />

                      <button
                        className="btn__abrir_class"
                        style={{ width: "100%" }}
                      >
                        Abrir
                      </button>
                    </div>
                  </NavLink>
                </div>

                <div className="card__conteudo">
                  <NavLink
                    to={"/RD_Pardo003/classroom/id"}
                    className="link__card_class"
                  >
                    <div
                      className="img__class"
                      style={{
                        backgroundImage:
                          "url(https://i.ytimg.com/vi/S9uPNppGsGo/maxresdefault.jpg)",
                      }}
                    ></div>
                    <div className="text__class">
                      <h3>Curso de Python - Curso em video</h3>
                      <p>
                        Isso mostrará como você pode usar desenvolver usando
                        Python.
                      </p>
                      <ProgressBar now={now} label={`${now}%`} />

                      <button
                        className="btn__abrir_class"
                        style={{ width: "100%" }}
                      >
                        Abrir
                      </button>
                    </div>
                  </NavLink>
                </div>

                <div className="card__conteudo">
                  <NavLink
                    to={"/RD_Pardo003/classroom/id"}
                    className="link__card_class"
                  >
                    <div
                      className="img__class"
                      style={{
                        backgroundImage:
                          "url(https://i.ytimg.com/vi/yvCAX68TJMc/maxresdefault.jpg)",
                      }}
                    ></div>
                    <div className="text__class">
                      <h3>Curso de HTMl e CSS</h3>
                      <p>
                        Isso mostrará como você pode usar desenvolver usando
                        HTML e CSS.
                      </p>
                      <ProgressBar now={now} label={`${now}%`} />

                      <button
                        className="btn__abrir_class"
                        style={{ width: "100%" }}
                      >
                        Abrir
                      </button>
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Classroom;
