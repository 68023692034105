import React from "react";
import HeaderCommunity from "../../../Components/HeaderCommunity";

const Membros = () => {
  return (
    <div>
      <HeaderCommunity />
      Membros
    </div>
  );
};

export default Membros;
