import React from "react";
import HeaderCommunity from "../../../Components/HeaderCommunity";

const Calendario = () => {
  return (
    <div>
      <HeaderCommunity />
      Calendario
    </div>
  );
};

export default Calendario;
