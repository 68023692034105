const { REACT_APP_URL_API } = process.env;

export const login = (event, state) => {
  fetch(`${REACT_APP_URL_API}/apiv2/login/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': this.state.csrf,
    },

    credentials: 'include',
    body: JSON.stringify({
      username: this.state.username,
      password: this.state.password,
    }),
    redirect: 'follow',
  })
    .then(this.isResponseOk)
    .then((data) => {
      // console.log('log2', data);
      localStorage.setItem('@user', data.isAuthenticated);
      window.location.replace('home');
      this.setState({
        isAuthenticated: true,
        username: '',
        password: '',
        error: '',
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const logout = () => {
  localStorage.removeItem('@user');
  localStorage.removeItem('email');
  localStorage.removeItem('password');
  window.location.reload();

  fetch(`${REACT_APP_URL_API}/apiv2/logout/`, {
    credentials: 'include',
  })
    .then(this.isResponseOk)
    .then((data) => {
      this.setState({ isAuthenticated: false });
      window.location.replace('home');
      this.getCSRF();
    })
    .catch((err) => {});
};

export const getCSRF = () => {
  fetch(`${REACT_APP_URL_API}/apiv2/csrf/`, {
    credentials: 'include',
  })
    .then((res) => {
      let csrfToken = res.headers.get('X-CSRFToken');
      this.setState({ csrf: csrfToken });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getSession = () => {
  fetch(`${REACT_APP_URL_API}/apiv2/session/`, {
    credentials: 'include',
  })
    .then((res) => res.json())
    .then((data) => {
      // console.log(data);
      if (data.isAuthenticated) {
        this.setState({ isAuthenticated: true });
        window.location.replace('home');
        localStorage.setItem('@user', data.isAuthenticated);
      } else {
        this.setState({ isAuthenticated: false });
        this.getCSRF();
      }
    })
    .catch((err) => {
      console.log(err);
    });
};
