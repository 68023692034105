import React from "react";
import "./style.css";
import { GlobalContext } from "../../GlobalContext";
import LogoCliente from "../../Components/LogoCliente";
import Error from "../../Components/Error";
import OutlinedInput from "@mui/material/OutlinedInput";
import ButtonSubmit from "../../Components/ButtonSubmit";
import Help from "../../Components/Help";
import InputPassword from "../../Components/InputPassword";
import Loading from "../../Components/Loading";

const { REACT_APP_URL_API } = process.env;
class Auth extends React.Component {
  static contextType = GlobalContext;

  constructor(props) {
    super(props);
    this.state = {
      csrf: "",
      username: "",
      password: "",
      error: "",
      isAuthenticated: false,
    };
  }

  componentDidMount = () => {
    this.getSession();
  };

  getCSRF = () => {
    fetch(`${REACT_APP_URL_API}/apiv2/csrf/`, {
      credentials: "include",
    })
      .then((res) => {
        let csrfToken = res.headers.get("X-CSRFToken");
        this.setState({ csrf: csrfToken });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getSession = () => {
    fetch(`${REACT_APP_URL_API}/apiv2/session/`, {
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log(data);
        if (data.isAuthenticated) {
          this.setState({ isAuthenticated: true });
          window.location.replace("home");
          localStorage.setItem("@user", data.isAuthenticated);
        } else {
          this.setState({ isAuthenticated: false });
          this.getCSRF();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  whoami = () => {
    fetch(`${REACT_APP_URL_API}/apiv2/whoami/`, {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
      .then((res) => res.json())
      .then((data) => {
        console.log("You are logged in as: " + data.username);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handlePasswordChange = (event) => {
    this.setState({ password: event.target.value });
  };

  handleUserNameChange = (event) => {
    this.setState({ username: event.target.value });
  };

  isResponseOk(response) {
    if (response.status >= 200 && response.status <= 299) {
      return response.json();
    } else {
      throw Error(response.statusText);
    }
  }
  // newLogin = (event) => {
  //   // const teste = login(event, this.state);
  //   console.log(teste);
  // };
  login = (event) => {
    event.preventDefault();

    fetch(`${REACT_APP_URL_API}/apiv2/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.state.csrf,
      },

      credentials: "include",
      body: JSON.stringify({
        username: this.state.username,
        password: this.state.password,
      }),
      redirect: "follow",
    })
      .then(this.isResponseOk)
      .then((data) => {
        // console.log('log2', data);
        localStorage.setItem("@user", data.isAuthenticated);
        window.location.replace("home");
        this.setState({
          isAuthenticated: true,
          username: "",
          password: "",
          error: "",
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          error: <Error error={"Nome de usuário ou senha incorretos."} />,
        });
      });
  };

  login2 = (event) => {
    event.preventDefault();

    fetch(`${REACT_APP_URL_API}/apiv2/login/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFToken": this.state.csrf,
      },

      credentials: "include",
      body: JSON.stringify({
        username: localStorage.getItem("email"),
        password: localStorage.getItem("password"),
      }),
      redirect: "follow",
    })
      .then(this.isResponseOk)
      .then((data) => {
        // console.log('log2', data);
        localStorage.setItem("@user", data.isAuthenticated);
        window.location.replace("home");
        localStorage.removeItem("email");
        localStorage.removeItem("password");
        this.setState({
          isAuthenticated: true,
          username: localStorage.getItem("email"),
          password: localStorage.getItem("password"),
          error: "",
        });
      });
  };

  logout = () => {
    fetch(`${REACT_APP_URL_API}/apiv2/logout/`, {
      credentials: "include",
    })
      .then(this.isResponseOk)
      .then((data) => {
        console.log(data);

        this.setState({ isAuthenticated: false });
        this.getCSRF();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // create = () => {
  //     fetch('http://localhost:8000/apiv2/voucher_creation/', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'X-CSRFToken': this.state.csrf,
  //       },

  //       credentials: 'include',
  //       body: JSON.stringify({
  //         client_id: 'RD_Pardo003',
  //         voucher_id: 12,
  //       }),
  //     })
  //       .then((response) => response.text())
  //       .then((result) => console.log(result))
  //       .catch((error) => console.log('error', error));
  //   };

  render() {
    const cliente = this.context;

    if (!this.state.isAuthenticated) {
      return (
        <div>
          {localStorage.getItem("email") ? (
            <header className="header-login" onLoad={this.login2}>
              <LogoCliente />
              <Loading />
            </header>
          ) : (
            <div>
              <header className="header-login">
                <div className="logo__div">
                  <LogoCliente />
                </div>
              </header>
              <h1 className="title-login">LOGIN</h1>
              <form
                method="post"
                className="form-login-container"
                onSubmit={this.login}
              >
                <div className="email">
                  <OutlinedInput
                    type="email"
                    name="email"
                    placeholder="E-mail"
                    id="username"
                    value={this.state.username}
                    onChange={this.handleUserNameChange}
                  />
                </div>

                <div className="pin" onChange={this.handlePasswordChange}>
                  <InputPassword
                    id="password"
                    type="password"
                    name="password"
                    placeholder="Senha"
                    value={this.state.password}
                  />
                </div>

                <div>
                  {this.state.error && (
                    <small className="text-danger">{this.state.error}</small>
                  )}
                </div>
                <div className="button btn-submit">
                  <ButtonSubmit>Acessar</ButtonSubmit>
                </div>

                <div className="button cadastre">
                  <a href="cadastro">CADASTRE-SE</a>
                </div>
                <a
                  className="recovery"
                  href="https://test.net4guest.com/guest/benefits/password_reset/"
                  target="blank"
                >
                  Esqueci minha senha{" "}
                </a>
              </form>
              <Help />
            </div>
          )}
        </div>
      );
    }
  }
}

export default Auth;
