import "./style.css";
import useEmblaCarousel from "embla-carousel-react";
import React, { useState, useEffect, useCallback } from "react";
import { DotButton, PrevButton, NextButton } from "./EmblaCarouselButtons";
import Autoplay from "embla-carousel-autoplay";
import Modal from "@mui/material/Modal";
import { Link, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import { GlobalContext } from "../../GlobalContext";
import Cookies from "universal-cookie";
import Loading from "../Loading";
import ButtonLink from "../ButtonLink/ButtonLink";

const { REACT_APP_URL_API } = process.env;

const style = {
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "var(--background)",
  border: "2px solid var(--title)",
  boxShadow: 24,
  p: 4,
};

export function Voucher() {
  const options = { delay: 5000 }; // Options
  const autoplayRoot = (emblaRoot) => emblaRoot.parentElement; // Root node
  const autoplay = Autoplay(options, autoplayRoot);
  const { session, setIndexF, index, whoami } = React.useContext(GlobalContext);

  const [viewportRef, embla] = useEmblaCarousel(
    { skipSnaps: false, loop: true },
    [autoplay]
  );
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);

  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  const handleClose = () => setOpen(0);
  const handleClose2 = () => setOpen2(0);

  const [abrir, setAbrir] = React.useState(false);
  const handleAbrir = () => setAbrir(true);
  const handleFechar = () => setAbrir(false);

  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();

  const teste = cliente_id;

  var raw = JSON.stringify({
    client_id: teste,
  });

  var raw2 = JSON.stringify({
    client_id: teste,
    voucher_id: "",
  });

  useEffect(() => {
    // function getCookie(name) {
    //   if (!document.cookie) {
    //     return null;
    //   }

    //   const xsrfCookies = document.cookie
    //     .split(';')
    //     .map((c) => c.trim())
    //     .filter((c) => c.startsWith(name + '='));

    //   if (xsrfCookies.length === 0) {
    //     return null;
    //   }
    //   return decodeURIComponent(xsrfCookies[0].split('=')[1]);
    // }

    // const token = getCookie('csrftoken');
    // console.log(token);

    const cookies = new Cookies();
    const token = cookies.get("csrftoken");
    // console.log('token', token);

    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: "POST",
        credentials: "include",
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
        // setIndexF(json);
      });
  }, []);

  const index1 = () => {
    // function getCookie(name) {
    //   if (!document.cookie) {
    //     return null;
    //   }

    //   const xsrfCookies = document.cookie
    //     .split(';')
    //     .map((c) => c.trim())
    //     .filter((c) => c.startsWith(name + '='));

    //   if (xsrfCookies.length === 0) {
    //     return null;
    //   }
    //   return decodeURIComponent(xsrfCookies[0].split('=')[1]);
    // }

    // const token = getCookie('csrftoken');
    // console.log(token);

    const cookies = new Cookies();
    const token = cookies.get("csrftoken");

    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: "POST",
        credentials: "include",
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
        setIndexF(json);
      });
  };

  const [create, setCreate] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const [open, setOpen] = React.useState(0);
  const [open2, setOpen2] = React.useState(0);

  const handleOpen = (id) => {
    setTimeout(() => {
      setLoading(true);
    });

    index1();

    const cookies = new Cookies();
    const token = cookies.get("csrftoken");

    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": token,
    });
    fetch(`${REACT_APP_URL_API}/apiv2/voucher_creation/`, {
      method: "POST",
      credentials: "include",
      headers,
      body: JSON.stringify({
        client_id: teste,
        voucher_id: id,
      }),
    })
      .then((response) => response.json())

      .then((response) => {
        setCreate(response.status);
        index1();
      })

      .catch((erro) => console.log(erro));
    index1();

    setTimeout(() => {
      setLoading(false);
      setOpen(id);
    }, 1500);
    index1();
  };

  const handleOpen2 = (id) => {
    setOpen2(id);
  };

  if (loading)
    return (
      <div>
        {" "}
        <Loading />
      </div>
    );
  if (data === null) return null;
  if (index === null) return null;
  console.log("dataa", data);

  const logoCliente = data[0]?.response.logo;

  return (
    <>
      <div className="embla">
        <div className="embla__viewport " ref={viewportRef}>
          {data.map((item, index) => (
            <div className="embla__container" key={index}>
              {item.response.voucher.map((cupom) => (
                <div
                  className="embla__slide voucher-container div-btn-voucher cont-slide"
                  key={cupom.id}
                >
                  {" "}
                  {!cupom.custom_logo && "null" ? (
                    <div
                      className="div-cont-img"
                      style={{ width: "150px", height: "120px" }}
                    >
                      {/* <img src={`${REACT_APP_URL_API}` + logoCliente} /> */}
                      <a href={`/${cliente_id}/${cupom.id}/parceiro`}>
                        <img
                          src={
                            `${REACT_APP_URL_API}/media/` +
                            cupom.logo_empresa_parceira
                          }
                        />
                      </a>
                    </div>
                  ) : (
                    <div
                      className="div-cont-img"
                      style={{ width: "150px", height: "120px" }}
                    >
                      {/* <img src={`${REACT_APP_URL_API}` + logoCliente} /> */}
                      <a href={`/${cliente_id}/${cupom.id}/parceiro`}>
                        {" "}
                        <img src={`${REACT_APP_URL_API}` + cupom.custom_logo} />
                      </a>
                    </div>
                  )}
                  <div
                    style={{
                      height: "60px",
                      width: "100%",
                      textAlign: "center",
                      margin: "0 auto",
                      padding: " 0 10px",
                    }}
                  >
                    <p style={{ color: "var(--secondary)" }}>
                      {" "}
                      {cupom.descricao}
                    </p>
                  </div>
                  <div className="box-button btn-ver-voucher div-btn-voucher">
                    {cupom.controle[0] === null && (
                      <div className="div-btn-gerar">
                        <button
                          style={{
                            background: "var(--title)",
                            color: "var(--background)",
                          }}
                          onClick={handleAbrir}
                        >
                          Ver voucher
                        </button>
                      </div>
                    )}

                    {whoami.isAuthenticated !== false &&
                    cupom.controle[0] === "create" ? (
                      <div className="div-btn-gerar">
                        <button
                          id="cupom.id"
                          onClick={() => handleOpen(cupom.id)}
                          style={{
                            backgroundImage: `linear-gradient(135deg, ${cupom.custom_color1} , ${cupom.custom_color2} )`,
                          }}
                          className="btn-voucher-gerar"
                        >
                          Gerar voucher
                        </button>
                      </div>
                    ) : null}

                    {whoami.isAuthenticated !== false &&
                      cupom.controle[0] == "show" && (
                        <div className="div-btn-gerar">
                          <button
                            style={{
                              background: "var(--background-main)",
                              border: "dashed 1px var(--primary)",
                              color: "var(--primary)",
                            }}
                            id="cupom.id"
                            onClick={() => handleOpen2(cupom.id)}
                          >
                            Ver voucher
                          </button>
                        </div>
                      )}
                  </div>
                  <div>
                    {cupom.controle[0] === "show" ? (
                      <Modal
                        open={cupom.id === open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        id={cupom.id}
                      >
                        {cupom.controle[0] === "show" ? (
                          <div className="container-card">
                            <div>
                              <h1 className="description">
                                Voucher gerado com sucesso
                              </h1>
                            </div>

                            <div
                              className="cupom-card"
                              style={{
                                background: `linear-gradient(135deg, ${cupom.custom_color1} , ${cupom.custom_color2} )`,
                              }}
                            >
                              <div className="div-img-cupom">
                                <img
                                  src={`${REACT_APP_URL_API}` + logoCliente}
                                  className="logo-cupom"
                                />
                                {cupom.custom_logo && "null" ? (
                                  <img
                                    src={
                                      `${REACT_APP_URL_API}` + cupom.custom_logo
                                    }
                                    className="logo-cupom"
                                    id={cupom.id}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={
                                      `${REACT_APP_URL_API}/media/` +
                                      cupom.logo_empresa_parceira
                                    }
                                    className="logo-cupom"
                                    id={cupom.id}
                                    alt=""
                                  />
                                )}
                              </div>
                              <h2 className="desconto" id={cupom.id}>
                                {cupom.desconto}
                              </h2>

                              <div className="container-descricao">
                                <h2 id={cupom.id}>{cupom.descricao}</h2>
                              </div>

                              <div className="cupom-row">
                                {/* <span className="cupom-code"></span> */}

                                {cupom.controle[0] === "show" ? (
                                  <span>
                                    {cupom.url !== null ? (
                                      <button className="btn-url">
                                        <a href={cupom.url} target="_blank">
                                          Acessar Vantagem
                                        </a>
                                      </button>
                                    ) : (
                                      <span className="cupom-code">
                                        {cupom.controle[1]}
                                      </span>
                                    )}
                                  </span>
                                ) : null}
                              </div>

                              <p> valido até {cupom.controle[2]}</p>

                              <button className="fechar" onClick={handleClose}>
                                X
                              </button>
                            </div>
                          </div>
                        ) : (
                          <Loading />
                        )}
                      </Modal>
                    ) : null}

                    {whoami.isAuthenticated !== false ? (
                      <Modal
                        open={cupom.id === open2}
                        onClose={handleClose2}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        id={cupom.id}
                      >
                        <div className="container-card">
                          <div>
                            <h1 className="description">
                              Voucher gerado com sucesso
                            </h1>
                          </div>

                          <div
                            className="cupom-card"
                            style={{
                              background: `linear-gradient(135deg, ${cupom.custom_color1} , ${cupom.custom_color2} )`,
                            }}
                          >
                            <div className="div-img-cupom">
                              <img
                                src={`${REACT_APP_URL_API}` + logoCliente}
                                className="logo-cupom"
                              />

                              {!cupom.custom_logo && "null" ? (
                                <img
                                  src={
                                    `${REACT_APP_URL_API}/media/` +
                                    cupom.logo_empresa_parceira
                                  }
                                  className="logo-cupom"
                                  id={cupom.id}
                                  alt=""
                                />
                              ) : (
                                <img
                                  src={
                                    `${REACT_APP_URL_API}` + cupom.custom_logo
                                  }
                                  className="logo-cupom"
                                  id={cupom.id}
                                  alt=""
                                />
                              )}
                            </div>

                            <h2 className="desconto" id={cupom.id}>
                              {cupom.desconto}
                            </h2>

                            <div className="container-descricao">
                              <h2 id={cupom.id}>{cupom.descricao}</h2>
                            </div>

                            <div className="cupom-row">
                              {/* <span className="cupom-code"></span> */}

                              {cupom.controle[0] === "show" ? (
                                <span>
                                  {cupom.url !== null ? (
                                    <button className="btn-url">
                                      <a href={cupom.url} target="_blank">
                                        Acessar Vantagem
                                      </a>
                                    </button>
                                  ) : (
                                    <span className="cupom-code">
                                      {cupom.controle[1]}
                                    </span>
                                  )}
                                </span>
                              ) : null}
                            </div>

                            <p> valido até {cupom.controle[2]}</p>

                            <button className="fechar" onClick={handleClose2}>
                              X
                            </button>
                          </div>
                        </div>
                      </Modal>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>
          ))}

          <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
          <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </div>
      </div>
      <div className="embla__dots">
        {scrollSnaps.map((_, index) => (
          <DotButton
            key={index}
            selected={index === selectedIndex}
            onClick={() => scrollTo(index)}
          />
        ))}
      </div>

      <Modal
        open={abrir}
        onClose={handleFechar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p
              style={{
                color: "var(--title)",
                padding: "10px",
                marginBottom: "20px",
              }}
            >
              Você não está logado
            </p>
            <div>
              {" "}
              {/* <Link
                to="auth"
                style={{
                  color: '#fff',
                  padding: '10px',
                  fontSize: '20px',
                  background: 'var(--quartary)',
                  marginTop: '30px',
                  borderRadius: '8px',
                }}
              >
                Fazer login
              </Link> */}
              <ButtonLink
                to="auth"
                style={{
                  padding: "10px",
                  fontSize: "20px",
                  marginTop: "30px",
                  borderRadius: "8px",
                  background: `linear-gradient(135deg, ${data[0].response.cor_custom} 75%, ${data[0].response.cor_custom2} 100%)`,
                }}
              >
                {" "}
                Fazer login
              </ButtonLink>
            </div>

            <button
              className="close"
              onClick={handleFechar}
              style={{
                background: `linear-gradient(135deg, ${data[0].response.cor_custom} 75%, ${data[0].response.cor_custom2} 100%)`,
              }}
            >
              X
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
}
