export const steps = [
  {
    selector: '.fifth-step',
    content: 'Clique aqui para acessar o seu perfil ou sair ',
  },

  {
    selector: '.sixth-step',
    content: 'Aqui você verá os voucher que estarão em destaque',
  },

  {
    selector: '.first-step',
    content: 'Clique aqui para ter acesso a todas as vantagens',
  },
  {
    selector: '.second-step',
    content: 'Clique aqui para participar de sorteios exclusivos',
  },
  {
    selector: '.third-step',
    content:
      'Clique aqui para ter acesso a informativos, beneficios, e muitos mais',
  },
  {
    selector: '.fourth-step',
    content: 'Clique aqui para ter acesso a eventos exclusivos',
  },
];
