import React from "react";
import "./style.css";
import HeaderCommunity from "../../../Components/HeaderCommunity";
import ProgressBar from "react-bootstrap/ProgressBar";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ReactPlayer from "react-player";
const ClassroomVideo = () => {
  const now = 64;
  return (
    <div>
      <HeaderCommunity />
      <div className="container__class">
        <div className="container-class-geral">
          {/* <div className="container_class"> */}
          <div className="container__conteudo_class">
            <div className="div__grid_video">
              <div className="div__progresso_video">
                <div className="div__progresso_conteudo">
                  <h3>O Protocolo de Programação</h3>
                  <ProgressBar now={now} label={`${now}%`} />
                  <div>
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <h3 className="text__acordion">
                          Nossas jornadas de programação
                        </h3>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="div__btn_video">
                          <button>Planos de Carreira</button>
                          <button>Onde aprender</button>
                        </div>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ArrowDropDownIcon />}
                        aria-controls="panel2-content"
                        id="panel2-header"
                      >
                        <h3 className="text__acordion">Produtividade</h3>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="div__btn_video">
                          <button>Estabelecendo objetivos</button>
                          <button>Hábitos saudáveis</button>
                          <button>Mentalidade</button>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </div>
              </div>
              <div className="card__video">
                <div className="card__video_conteudo">
                  <div className="div__conteudo_video">
                    <div className="div__title_video">
                      <h3>Curso de Python</h3>
                      <CheckCircleOutlineIcon />
                    </div>

                    <div className="container__player_video">
                      <div className="player__video">
                        <ReactPlayer
                          url="https://www.youtube.com/watch?v=31llNGKWDdo&list=PLvE-ZAFRgX8hnECDn1v9HNTI71veL3oW0&index=5"
                          controls={true}
                        />
                      </div>
                      <p>
                        Agora chegou a hora de aprender os comandos básicos do
                        Python e fazer os primeiros programas em Linguagem
                        Python. Gostou da aula? Então torne-se um Gafanhoto
                        APOIADOR do CursoemVídeo acessando o site
                        cursoemvideo.com/apoie Nesta aula, vamos aprender a
                        utilizar as instruções print() e input() para realizar
                        interação com o usuário. Além disso, vamos ver o
                        conceito fundamental de variável e como elas se
                        comportam dentro de um programa em Python. Além disso,
                        teremos exercícios resolvidos em Python, com desafios de
                        programação, onde você terá que criar programas em
                        Python de acordo com enunciados propostos durante a
                        aula.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ClassroomVideo;
