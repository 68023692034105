import React from 'react';
import { GlobalContext, GlobalStorage } from './GlobalContext';
import Rotas from './Rotas';
import './styles/global.css';
import { useTour, TourProvider } from '@reactour/tour';
import { steps } from './Components/Tour/Steps';
import ReactGA from 'react-ga';
ReactGA.initialize('UA-236480625-2');
ReactGA.pageview(window.location.pathname + window.location.search);

ReactGA.set({
  username: 'ailson',
  // Other relevant user information
});

const App = () => {
  const setDarkMode = () => {
    document.querySelector('*').setAttribute('data-theme', 'dark');
    localStorage.setItem('selectedTheme', 'dark');
  };
  const setLightMode = () => {
    document.querySelector('*').setAttribute('data-theme', 'light');
    localStorage.setItem('selectedTheme', 'light');
  };
  const selectedTheme = localStorage.getItem('selectedTheme');
  const cliente = React.useContext(GlobalContext);
  if (selectedTheme === 'dark') {
    setDarkMode();
  }

  const radius = 8;

  return (
    <TourProvider
      steps={steps}
      onClickMask={({ setCurrentStep, currentStep, steps, setIsOpen }) => {
        if (steps) {
          if (currentStep === steps.length - 1) {
            setIsOpen(false);
          }
          setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1));
        }
      }}
      styles={{
        popover: (base) => ({
          ...base,
          '--reactour-accent': '#ef5a3d',
          borderRadius: radius,
          margin: 10,
        }),
        maskArea: (base) => ({ ...base, rx: radius, color: 'red' }),

        badge: (base) => ({
          ...base,
        }),
        controls: (base) => ({ ...base, marginTop: 30 }),
        close: (base) => ({
          ...base,
          top: 10,
          right: 10,
          width: 12,
          color: '#ef5a3d',
        }),
      }}
    >
      <GlobalStorage>
        <Rotas />
      </GlobalStorage>
    </TourProvider>
  );
};

export default App;
