import { Link } from 'react-router-dom';
import './style.css';
import { useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
const { REACT_APP_URL_API } = process.env;

const ButtonSubmit = ({ children, ...props }) => {
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);

  if (data === null) return null;

  return (
    <>
      <div className="box-button">
        <button
          className="button-submit"
          style={{
            background: `linear-gradient(135deg, ${data[0].response.cor_custom} 75%, ${data[0].response.cor_custom2} 100%)`,
          }}
          {...props}
        >
          {children}
        </button>
      </div>
    </>
  );
};

export default ButtonSubmit;
