import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import './styles/global.css';
import Home from './Screens/Home';
import Cadastro from './Screens/Cadastro';
import { Clube } from './Screens/Clube';
import Cronometro from './Screens/SorteioOnline/Cronometro';
import Resultado from './Screens/SorteioOnline/Resultado';
import SlotMachine from './Screens/SorteioOnline/SlotMachine/SlotMachine';
import NotFound from './Screens/NotFound';
import Auth from './Screens/Login/Auth';
import { GlobalContext, GlobalStorage } from './GlobalContext';
import { useParams } from 'react-router-dom';
import Time from './Screens/SorteioOnline/Time';
import Perfil from './Screens/Perfil';
import Parceiro from './Screens/Parceiro';
import Mural from './Screens/Mural';
import Eventos from './Screens/Eventos';
import firebase from './Services/firebase';
import FacilitiesHome from './Screens/Facilities/FacilitiesHome';
import FacilitiesCardapio from './Screens/Facilities/FacilitiesCardapio';
import CardapioItem from './Screens/Facilities/CardapioItem';
import { Sorteio } from './Screens/Sorteio';
import Demo from './Screens/Demo';
import SorteioPainel from './Screens/SorteioPainel';
import Community from './Screens/Comunidade/Community';
import Calendario from './Screens/Comunidade/Calendario';
import Classroom from './Screens/Comunidade/Classroom';
import Membros from './Screens/Comunidade/Membros';
import ClassroomVideo from './Screens/Comunidade/ClassroomVideo';
import Ganhador from './Screens/SorteioOnline/Ganhador';
import Carrinho from './Screens/Facilities/Carrinho';
import Concierge from './Screens/Facilities/Concierge';
import Diretorio from './Screens/Facilities/Diretorio';
import { Extrato } from './Screens/Facilities/Extrato';

const RoutesListener = () => {
  const location = useLocation();

  useEffect(() => {
    firebase.analytics().setCurrentScreen(location.pathname);
  }, [location]);

  return <></>;
};
const Rotas = () => {
  const { cliente_id } = useParams();
  const { session, whoami } = React.useContext(GlobalContext);

  return (
    <BrowserRouter>
      <Routes>
        <RoutesListener />
        <Route path="/:cliente_id/:partner_id/*" element={<Home />} />
        <Route path="/:cliente_id/*" element={<Home />} />
        <Route path="/:cliente_id/:partner_id/auth/" element={<Auth />} />
        <Route path="/:cliente_id/auth/" element={<Auth />} />
        <Route path="/:cliente_id/:partner_id/clube" element={<Clube />} />
        <Route path="/:cliente_id/clube" element={<Clube />} />

        <Route
          path="/:cliente_id/:partner_id/cadastro/*"
          element={<Cadastro />}
        />
        <Route path="/:cliente_id/cadastro/*" element={<Cadastro />} />

        <Route path="/:cliente_id/:partner_id/mural/*" element={<Mural />} />
        <Route path="/:cliente_id/mural/*" element={<Mural />} />

        <Route path="/:cliente_id/:id/parceiro/*" element={<Parceiro />} />

        {whoami.isAuthenticated !== false ? (
          <Route path="/:cliente_id/perfil" element={<Perfil />} />
        ) : (
          <Route path="/:cliente_id/perfil" element={<NotFound />} />
        )}

        <Route
          path="/:cliente_id/:partner_id/sorteio/*"
          element={<Sorteio />}
        />
        <Route path="/:cliente_id/sorteio/*" element={<Sorteio />} />

        <Route
          path="/:cliente_id/:partner_id/painel/*"
          element={<SorteioPainel />}
        />
        <Route path="/:cliente_id/painel/*" element={<SorteioPainel />} />

        <Route path="/:cliente_id/:id/painel" element={<SorteioPainel />} />
        <Route
          path="/:cliente_id/home/:id/painel"
          element={<SorteioPainel />}
        />
        <Route
          path="/:cliente_id/home/:id_partner/:id/painel"
          element={<SorteioPainel />}
        />

        <Route path="/:cliente_id/:id/painel" element={<SorteioPainel />} />
        <Route
          path="/:cliente_id/:id_partner/:id/painel"
          element={<SorteioPainel />}
        />

        <Route
          path="/:cliente_id/:partner_id/eventos/*"
          element={<Eventos />}
        />
        <Route path="/:cliente_id/eventos/*" element={<Eventos />} />

        <Route path="/:cliente_id/:id/cronometro" element={<Cronometro />} />

        <Route path="/:cliente_id/:id/time" element={<Time />} />
        <Route path="/:cliente_id/home/:id/time" element={<Time />} />
        <Route
          path="/:cliente_id/home/:id_partner/:id/time"
          element={<Time />}
        />

        <Route path="/:cliente_id/:id/time" element={<Time />} />
        <Route path="/:cliente_id/:id_partner/:id/time" element={<Time />} />

        <Route path="/:cliente_id/:id/resultado" element={<Resultado />} />
        <Route path="/:cliente_id/:id/ganhador" element={<Ganhador />} />
        <Route path="/:cliente_id/:id/slotmachine" element={<SlotMachine />} />
        {/* <Route path="/:cliente_id/sucesso" element={<Sucesso />} /> */}
        <Route path="/:cliente_id/global" element={<GlobalStorage />} />

        <Route path="/:cliente_id/facilities" element={<FacilitiesHome />} />
        <Route path="/:cliente_id/cardapio" element={<FacilitiesCardapio />} />
        <Route path="/:cliente_id/cardapioitem" element={<CardapioItem />} />
        <Route path="/:cliente_id/carrinho" element={<Carrinho />} />
        <Route path="/:cliente_id/concierge" element={<Concierge />} />
        <Route path="/:cliente_id/diretorio" element={<Diretorio />} />
        <Route path="/:cliente_id/extrato" element={<Extrato />} />

        <Route path="/:cliente_id/demo" element={<Demo />} />
        <Route path="/:cliente_id/community" element={<Community />} />
        <Route path="/:cliente_id/calendario" element={<Calendario />} />

        <Route path="/:cliente_id/classroom" element={<Classroom />} />
        <Route path="/:cliente_id/classroom/:id" element={<ClassroomVideo />} />
        <Route path="/:cliente_id/membros" element={<Membros />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Rotas;
