import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function Termos() {
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <span onClick={handleClickOpen} style={{ color: 'var(--secondary)' }}>
        Li e concordo com os{' '}
        <span style={{ cursor: 'pointer', color: '#42a5f5' }}>
          termos e condições.
        </span>
      </span>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          <p style={{ color: 'var(--secondary)' }}>
            {' '}
            {'Termos de Acesso do Clube de Vantagens para Beneficiários'}
          </p>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <p style={{ color: 'var(--secondary)', textAlign: 'justify' }}>
              1. Cadastro e Acesso 1.1. Para acessar o Clube de Vantagens, é
              necessário realizar um cadastro prévio fornecendo informações
              básicas, precisas e atualizadas. 1.2. O cadastro do beneficiário
              está sujeito à Lei Geral de Proteção de Dados (LGPD), garantindo a
              privacidade e a proteção de seus dados pessoais. 1.3. O
              beneficiário é responsável pela veracidade das informações
              fornecidas durante o cadastro. 2. Uso do Clube de Vantagens 2.1. O
              acesso ao Clube de Vantagens é gratuito para os beneficiários.
              2.2. Ao acessar o Clube, o beneficiário terá a possibilidade de
              visualizar vouchers de vantagens fornecidos pelas empresas
              parceiras da sua empresa ou pelo fornecido pelo próprio Clube de
              Vantagens, são oportunidade com desconto ou gratuitas. 2.3. Os
              vouchers podem solicitar informações sobre o compartilhamento de
              dados pessoais com a empresa parceira para que ela possa te
              identificar, cabendo ao beneficiário avaliar a concessão dessas
              informações quando necessário. 2.4. O beneficiário tem o direito
              de participar de sorteios como incentivo ao trabalhador, de acordo
              com o artigo 30, inciso II, da Lei nº 8.666/1993, que permite a
              realização de sorteios como parte de ações de estímulo ou
              premiação de servidores ou colaboradores. 2.5. O Clube de
              Vantagens também oferece acesso a dados, informações e publicações
              relevantes fornecidas pela Empresa do beneficiário, incluindo
              atualizações, eventos e novidades. 3. Responsabilidades 3.1. O
              beneficiário é responsável pelo uso adequado do Clube de
              Vantagens, respeitando todas as regras, termos e condições
              estabelecidas. 3.2. O beneficiário concorda em não utilizar o
              Clube de Vantagens para fins ilegais, ofensivos, difamatórios,
              discriminatórios, obscenos ou que violem quaisquer direitos de
              terceiros. 3.3. O beneficiário deve tomar as devidas precauções de
              segurança, incluindo a proteção de suas credenciais de acesso e a
              não divulgação de informações confidenciais a terceiros. 4.
              Modificações e Cancelamento 4.1. O Clube de Vantagens reserva-se o
              direito de realizar modificações, atualizações ou suspender o
              acesso ao Clube, a qualquer momento, sem aviso prévio. 4.2. O
              beneficiário tem o direito de cancelar sua participação no Clube
              de Vantagens a qualquer momento, mediante solicitação ou através
              das opções disponibilizadas no próprio sistema. 5. Disposições
              Gerais 5.1. Estes termos de acesso constituem o acordo integral
              entre o beneficiário e o Clube de Vantagens, substituindo qualquer
              acordo anterior ou contemporâneo. 5.2. Qualquer falha em fazer
              cumprir qualquer disposição destes termos não será considerada uma
              renúncia a esses direitos. 5.3. Estes termos de acesso serão
              regidos pelas leis brasileiras, e quaisquer disputas decorrentes
              ou relacionadas a eles serão submetidas à jurisdição exclusiva dos
              tribunais competentes.
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
