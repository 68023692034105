import React, { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';
import { useParams } from 'react-router-dom';

const { REACT_APP_URL_API } = process.env;

export const GlobalContext = React.createContext();
export const GlobalStorage = ({ children }) => {
  let [item, setItem] = React.useState(null);
  let [data, setData] = React.useState(null);
  let [session, setSession] = React.useState(null);

  const { cliente_id } = useParams();

  const teste = cliente_id;

  var raw = JSON.stringify({
    client_id: teste,
  });

  useEffect(() => {
    fetch(`${REACT_APP_URL_API}/apiv2/whoami/`, {
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((res) => res.json())
      .then((json) => setItem(json))

      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);

  let [sorteio, setSorteio] = React.useState([]);
  const { id } = useParams();

  var raw2 = JSON.stringify({
    client_id: teste,
    sorteio_id: id,
  });

  // React.useEffect(() => {
  //   const cookies = new Cookies();
  //   const token = cookies.get('csrftoken');
  //   // console.log('token', token);

  //   const headers = new Headers({
  //     'Content-Type': 'application/json',
  //     'X-CSRFToken': token,
  //   });

  //   window
  //     .fetch(`${REACT_APP_URL_API}/apiv2/sorteio_screen_controller/`, {
  //       method: 'POST',
  //       credentials: 'include',
  //       headers,
  //       body: raw2,
  //     })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((json) => {
  //       setSorteio(json);
  //     });
  // }, []);

  // React.useEffect(() => {
  //   const cookies = new Cookies();
  //   const token = cookies.get('csrftoken');
  //   // console.log('token', token);

  //   const headers = new Headers({
  //     'Content-Type': 'application/json',
  //     'X-CSRFToken': token,
  //   });

  //   window
  //     .fetch(`${REACT_APP_URL_API}/apiv2/session/`, {
  //       method: 'POST',
  //       credentials: 'include',
  //       headers,
  //       body: raw,
  //     })
  //     .then((response) => {
  //       return response.json();
  //     })
  //     .then((json) => {
  //       setSession(json);
  //     });
  // }, []);

  const whoami = item;
  // const session = '2';

  if (sorteio === null) return null;

  const sorteado = sorteio[0]?.chosen_name;

  if (data === null) return null;
  if (item === null) return null;
  // if (session === null) return null;

  const voucher = item.full_name;

  return (
    <div>
      <GlobalContext.Provider
        value={{
          data,
          teste,
          item,
          voucher,
          sorteio,
          sorteado,
          // session,
          whoami,
        }}
      >
        {children}
      </GlobalContext.Provider>
    </div>
  );
};
