import './style.css';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import Tooltip from '@mui/material/Tooltip';
import { GlobalContext } from '../../GlobalContext';
import { logout } from '../../Screens/Login/function';
import Logout from '@mui/icons-material/Logout';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { Link, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Badge from '@mui/material/Badge';
import { styled } from '@mui/material/styles';

const { REACT_APP_URL_API } = process.env;

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -33,
    top: 5,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}));

export default function AvatarMenu() {
  const { item } = React.useContext(GlobalContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);
  const pessoaTeste = localStorage.getItem(item.username);

  if (data === null) return null;

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
        <Tooltip>
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            <StyledBadge badgeContent={0} color="secondary">
              <Avatar
                className="fifth-step"
                style={{
                  background: `linear-gradient(135deg, ${data[0].response.cor_custom} 75%, ${data[0].response.cor_custom2} 100%)`,
                  position: 'absolute',
                  top: '-5px',
                  left: '-10px',
                  border: `1px solid ${data[0].response.cor_custom}`,
                }}
                src={
                  `${REACT_APP_URL_API}` + item.img_path
                    ? `${REACT_APP_URL_API}` + item.img_path
                    : ''
                }
              >
                {item.full_name[0].toUpperCase()}
              </Avatar>
            </StyledBadge>
          </IconButton>
        </Tooltip>
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&:before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem>
          <Link to={`/${cliente_id}/perfil`} style={{ color: '#111' }}>
            {' '}
            <ListItemIcon>
              <PersonOutlineOutlinedIcon fontSize="small" /> Perfil{' '}
            </ListItemIcon>
          </Link>
        </MenuItem>

        {item.company == 'Inscrito' ? (
          <MenuItem>
            <ListItemIcon>
              <FavoriteBorderIcon fontSize="small" /> Seja um apoiador
            </ListItemIcon>

            <a
              href="https://apoia.se/hashtagsal"
              style={{ color: '#111' }}
              target="_blank"
            ></a>
          </MenuItem>
        ) : null}

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout fontSize="small" /> Sair
          </ListItemIcon>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
