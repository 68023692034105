import React from "react";
import "./style.css";
import HeaderCommunity from "../../../Components/HeaderCommunity";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import TuneIcon from "@mui/icons-material/Tune";
import ThumbUpAltOutlinedIcon from "@mui/icons-material/ThumbUpAltOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";

const Community = () => {
  const handleClick = () => {
    console.info("You clicked the Chip.");
  };

  return (
    <div>
      <HeaderCommunity />

      <div className="container__comu">
        <div className="container_community">
          <div>
            <div className="card_post">
              <span size="40" className="span_img">
                <img
                  src="https://assets.skool.com/f/35531579661d48b8b4615395cd7cff53/ab72e4c04c9a464fb792390c3d84c0624a1111068fff417d85785917e27589c6-sm.jpg"
                  alt="Ailson alves pereira Ailsonn"
                />{" "}
              </span>
              <p>Escreva algo</p>
            </div>
            <div className="container_chip">
              <Stack direction="row" spacing={1}>
                <Chip label="Todas" onClick={handleClick} />
                <Chip label="Geral" variant="outlined" onClick={handleClick} />
                <Chip
                  label="Questões"
                  variant="outlined"
                  onClick={handleClick}
                />
                <Chip label="Regras" variant="outlined" onClick={handleClick} />
                <Chip label="Rede" variant="outlined" onClick={handleClick} />
              </Stack>
              <div className="div_icon_config">
                <TuneIcon fontSize="small" />
              </div>
            </div>
            <div className="card_post_friends">
              <div className="div__post__friends">
                <div className="card_postagens">
                  <div className="post_header">
                    <span size="40" className="span_img">
                      <img
                        src="https://assets.skool.com/f/35531579661d48b8b4615395cd7cff53/ab72e4c04c9a464fb792390c3d84c0624a1111068fff417d85785917e27589c6-sm.jpg"
                        alt="Ailson alves pereira Ailsonn"
                      />{" "}
                    </span>
                    <div className="div_name_post_community">
                      <a href="#">Ailson Alves Pereira</a>
                      <p>8 dias atrás</p>
                    </div>
                  </div>
                  <div className="text_post">
                    <h3>Passo 1 – apresente-se</h3>
                    <p>De onde você é? O que você faz?</p>
                  </div>
                  <div className="div__like">
                    <div>
                      {" "}
                      <ThumbUpAltOutlinedIcon /> <span>0</span>
                    </div>
                    <div>
                      {" "}
                      <ChatBubbleOutlineOutlinedIcon /> <span>0</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="div_profile_community">
            <div className="container_profile_community">
              <div className="card__profile__img"></div>
              <div className="text_profile">
                <h1>Geometric Profits - FREE</h1>
                <p>Helping you build and scale your business.</p>
              </div>
              <div className="link__profile">
                <a href="#">🔗 Create Your Community </a>
                <br />
                <a href="#">🔗 Learn Copywriting Learn about</a>
                <br />
                <a href="#">🔗 Affiliate Marketing</a>
              </div>

              <div className="info_profile_details"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Community;
