import React, { useState, useEffect } from 'react';
import './style.css';
import Cookies from 'universal-cookie';
import { Link, useParams } from 'react-router-dom';
import LogoClube from '../../Assets/logoClube.svg';

const { REACT_APP_URL_API } = process.env;

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};

const Botaofacilities = () => {
  const [data, setData] = React.useState(null);
  const { cliente_id } = useParams();
  const teste = cliente_id;
  var raw = JSON.stringify({
    client_id: teste,
  });
  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  }, []);
  if (data === null) return null;

  const cor1 = data[0]?.response.cor_custom;
  const cor2 = data[0]?.response.cor_custom2;

  return (
    <div>
      {data.map((item, index) => (
        <div className="container__btn_facilities" key={index}>
          {item.response.menu
            .filter((btn) => btn.status === true)
            .sort((a, b) => a.ordem - b.ordem)
            .map((btn) => (
              <div key={btn.id}>
                {btn.tipo === 'CA' && (
                  <Link
                    to={`/${cliente_id}/cardapio`}
                    className="btn_facilities"
                    style={{
                      background: ` ${btn.cor_custom}`,
                    }}
                  >
                    <img src={`${REACT_APP_URL_API}` + btn.img} alt="" />

                    <p>{btn.nome}</p>
                  </Link>
                )}
                {/* <p></p> */}
                {btn.tipo === 'DI' && (
                  <Link
                    to={`/${cliente_id}/diretorio`}
                    className="btn_facilities"
                    style={{
                      background: ` ${btn.cor_custom}`,
                    }}
                  >
                    <img src={`${REACT_APP_URL_API}` + btn.img} alt="" />

                    <p>{btn.nome}</p>
                  </Link>
                )}

                {btn.tipo === 'EX' && (
                  <Link
                    to={`/${cliente_id}/extrato`}
                    className="btn_facilities"
                    style={{
                      background: ` ${btn.cor_custom}`,
                    }}
                  >
                    <img src={`${REACT_APP_URL_API}` + btn.img} alt="" />

                    <p>{btn.nome}</p>
                  </Link>
                )}
                {btn.tipo === 'CO' && (
                  <Link
                    to={`/${cliente_id}/concierge`}
                    className="btn_facilities"
                    style={{
                      background: ` ${btn.cor_custom}`,
                    }}
                  >
                    <img src={`${REACT_APP_URL_API}` + btn.img} alt="" />

                    <p>{btn.nome}</p>
                  </Link>
                )}

                {btn.tipo === 'CV' && (
                  <Link
                    to={`/${cliente_id}`}
                    className="btn_facilities"
                    style={{
                      background: ` ${btn.cor_custom}`,
                    }}
                  >
                    <img src={LogoClube} alt="" />
                    <p>Clube de Vantagens</p>
                  </Link>
                )}

                {btn.tipo === 'GE' && (
                  <a
                    href={btn.url}
                    className="btn_facilities"
                    target="_blank"
                    style={{
                      background: ` ${btn.cor_custom}`,
                    }}
                  >
                    <img src={`${REACT_APP_URL_API}` + btn.img} alt="" />
                    <p>{btn.nome}</p>
                  </a>
                )}
              </div>
            ))}

          {/* <Link
        to={`/${cliente_id}/concierge`}
        className="btn_facilities"
        style={{
          background: `linear-gradient(135deg, ${cor1} 75%, ${cor2} 100%)`,
        }}
      >
        <img src="https://facilities.net4guest.com/media/2/waiter.svg" alt="" />
        <p>Concierge</p>
      </Link>
      <Link
        to={`/${cliente_id}/diretorio`}
        className="btn_facilities"
        style={{
          background: `linear-gradient(135deg, ${cor1} 75%, ${cor2} 100%)`,
        }}
      >
        <img src="https://facilities.net4guest.com/media/1/book.svg" alt="" />
        <p>Diretório</p>
      </Link>
      <Link
        to={`/${cliente_id}/extrato`}
        className="btn_facilities"
        style={{
          background: `linear-gradient(135deg, ${cor1} 75%, ${cor2} 100%)`,
        }}
      >
        <img
          src="https://facilities.net4guest.com/media/None/extrato_OSy78Fb.svg"
          alt=""
        />
        <p>Extrato</p>
      </Link> */}

          <div
            className="btn_facilities"
            style={{
              background: `linear-gradient(135deg, ${cor1} 75%, ${cor2} 100%)`,
            }}
          >
            <img
              src="https://facilities.net4guest.com/media/859/icon_suporte.png"
              alt=""
            />
            <p>Suporte</p>
          </div>
          {/* 
          <Link
            to={`/${cliente_id}`}
            className="btn_facilities"
            style={{
              background: `linear-gradient(135deg, ${cor1} 75%, ${cor2} 100%)`,
            }}
          >
            <img src={LogoClube} alt="" />
            <p>Clube de Vantagens</p>
          </Link> */}
        </div>
      ))}
    </div>
  );
};

export default Botaofacilities;
