import React, { useState } from 'react';
import './style.css';

const ContadorCardapio = () => {
  const [counter, setCounter] = useState(0);

  const handleClick1 = () => {
    setCounter(counter + 1);
  };

  const handleClick2 = () => {
    setCounter(counter - 1);
  };

  return (
    <div>
      <div className="container-counter">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button className="btn-counter-remove" onClick={handleClick2}>
            -
          </button>{' '}
          <div>{counter}</div>
          <button className="btn-counter-add" onClick={handleClick1}>
            +
          </button>
        </div>
      </div>
    </div>
  );
};

export default ContadorCardapio;
