import firebase from 'firebase/compat/app';
// import { initializeApp } from 'firebase/app';
// import 'firebase/analytics';
// // const config = {
// //   apiKey: 'AIzaSyDwqzeMa8wFgPw4J5Tcmt2hN69HkF3otgA',
// //   authDomain: 'clube-de-vantagens-cddc2.firebaseapp.com',
// //   projectId: 'clube-de-vantagens-cddc2',
// //   storageBucket: 'clube-de-vantagens-cddc2.appspot.com',
// //   messagingSenderId: '601721597804',
// //   appId: '1:601721597804:web:df8df9b22d5123e37e0d49',
// //   measurementId: 'G-J3RPLN7X55',
// // };

// // firebase.initializeApp(config);

// // export default firebase;

// const config = {
//   apiKey: 'AIzaSyDwqzeMa8wFgPw4J5Tcmt2hN69HkF3otgA',
//   authDomain: 'clube-de-vantagens-cddc2.firebaseapp.com',
//   projectId: 'clube-de-vantagens-cddc2',
//   storageBucket: 'clube-de-vantagens-cddc2.appspot.com',
//   messagingSenderId: '601721597804',
//   appId: '1:601721597804:web:df8df9b22d5123e37e0d49',
//   measurementId: 'G-J3RPLN7X55',
// };

// firebase.initializeApp(config);

// export default firebase;

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getDatabase } from 'firebase/database';
import { getStorage } from 'firebase/storage';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const config = {
  apiKey: 'AIzaSyDwqzeMa8wFgPw4J5Tcmt2hN69HkF3otgA',
  authDomain: 'clube-de-vantagens-cddc2.firebaseapp.com',
  projectId: 'clube-de-vantagens-cddc2',
  storageBucket: 'clube-de-vantagens-cddc2.appspot.com',
  messagingSenderId: '601721597804',
  appId: '1:601721597804:web:df8df9b22d5123e37e0d49',
  measurementId: 'G-J3RPLN7X55',
};

// Initialize Firebase
const app = initializeApp(config);
const analytics = getAnalytics(app);
const database = getDatabase(app);
export const storage = getStorage(app);
export default firebase;
