import React from 'react';
import imgError from '../../Assets/robo.png';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../../GlobalContext';
import './style.css';

const NotFound = () => {
  const { cliente_id } = useParams();
  const cliente = React.useContext(GlobalContext);

  return (
    <>
      <div className="MainGrid">
        <div
          className="logo-error"
          style={{
            display: 'flex',
            marginTop: '-60px',
            padding: '10px',
          }}
        >
          <img
            src="https://net4guest.com/wp-content/uploads/2021/09/NET4GUEST-1024x591.png"
            style={{ width: '100px', margin: 'auto' }}
          />
        </div>
        <div className="container-error box">
          <img src={imgError} alt="" />

          <p>
            Ops...
            <br />
            Algo deu errado!
          </p>

          <a href="home">
            <button>Voltar</button>
          </a>
        </div>
      </div>
    </>
  );
};

export default NotFound;
