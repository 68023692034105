import Modal from '@mui/material/Modal';
import React, { useEffect, useState } from 'react';
import './style.css';
import { Header } from '../../Components/Header';
import Cookies from 'universal-cookie';
import { Link, useParams } from 'react-router-dom';
import Loading from '../../Components/Loading';
import NavigationIcon from '@mui/icons-material/Navigation';
import PublicIcon from '@mui/icons-material/Public';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { GlobalContext } from '../../GlobalContext';

const { REACT_APP_URL_API } = process.env;

const Parceiro = () => {
  const { session, whoami } = React.useContext(GlobalContext);

  const handleClose = () => setOpen(0);
  const handleClose2 = () => setOpen2(0);

  const [abrir, setAbrir] = React.useState(false);
  const handleAbrir = () => setAbrir(true);
  const handleFechar = () => setAbrir(false);

  const { id } = useParams();
  let [data, setData] = React.useState(null);
  const [create, setCreate] = useState(0);
  const [loading, setLoading] = useState(false);

  const [open, setOpen] = React.useState(0);
  const [open2, setOpen2] = React.useState(0);
  const { cliente_id } = useParams();
  const cliente = cliente_id;
  var raw = JSON.stringify({
    client_id: cliente,
  });

  const teste = cliente_id;

  useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  }, []);

  const index1 = () => {
    // function getCookie(name) {
    //   if (!document.cookie) {
    //     return null;
    //   }

    //   const xsrfCookies = document.cookie
    //     .split(';')
    //     .map((c) => c.trim())
    //     .filter((c) => c.startsWith(name + '='));

    //   if (xsrfCookies.length === 0) {
    //     return null;
    //   }
    //   return decodeURIComponent(xsrfCookies[0].split('=')[1]);
    // }

    // const token = getCookie('csrftoken');
    // console.log(token);
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  };

  const handleOpen = (id) => {
    // function getCookie(name) {
    //   if (!document.cookie) {
    //     return null;
    //   }

    //   const xsrfCookies = document.cookie
    //     .split(';')
    //     .map((c) => c.trim())
    //     .filter((c) => c.startsWith(name + '='));

    //   if (xsrfCookies.length === 0) {
    //     return null;
    //   }
    //   return decodeURIComponent(xsrfCookies[0].split('=')[1]);
    // }

    // const token = getCookie('csrftoken');
    // console.log(token);

    index1();
    setTimeout(() => {
      setLoading(true);
    });

    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });
    fetch(`${REACT_APP_URL_API}/apiv2/voucher_creation/`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        client_id: teste,
        voucher_id: id,
      }),
    })
      .then((response) => response.json())

      .then((response) => {
        setCreate(response.status);
        index1();
      })

      .catch((erro) => console.log(erro));

    setTimeout(() => {
      setLoading(false);
      setOpen(id);
    }, 1500);
    index1();
  };

  const handleOpen2 = (id) => {
    setOpen2(id);
  };

  if (data === null) return null;
  const logoCliente = data[0]?.response.logo;

  const name_parceiro = data[0]?.response.voucher.filter(
    (item) => item.id == id,
  )[0].nome_fantasia_empresa_parceira;

  const foto_parceiro = data[0]?.response.voucher.filter(
    (item) => item.id == id,
  )[0].logo_empresa_parceira;

  const nameFilter = data[0]?.response.voucher.filter(
    (item) => item.nome_fantasia_empresa_parceira == name_parceiro,
  );

  const descricaoFilter = data[0]?.response.voucher.filter(
    (item) => item.id == id,
  )[0].descricao_empresa_parceira;

  const facebookParceiro = data[0]?.response.voucher.filter(
    (item) => item.id == id,
  )[0].facebook_url_empresa_parceira;

  const instagramParceiro = data[0]?.response.voucher.filter(
    (item) => item.id == id,
  )[0].instagram_url_empresa_parceira;

  const whatsappParceiro = data[0]?.response.voucher.filter(
    (item) => item.id == id,
  )[0].whatsapp_url_empresa_parceira;

  const siteParceiro = data[0]?.response.voucher.filter(
    (item) => item.id == id,
  )[0].site_empresa_parceira;

  const mapaParceiro = data[0]?.response.voucher.filter(
    (item) => item.id == id,
  )[0].api_mapa_empresa_parceira;

  const corCustom = data[0]?.response.cor_custom;

  if (loading)
    return (
      <div>
        {' '}
        <Loading />
      </div>
    );

  return (
    <div>
      <Header />

      <div className="MainGrid">
        <div className="container-foto">
          <div>
            <img
              src={`${REACT_APP_URL_API}/media/` + foto_parceiro}
              className="foto-perfil-parceiro"
            />
          </div>
          <div>
            {' '}
            <p className="nome-perfil nome-parceiro">{name_parceiro}</p>
            <p className="sub-text">{descricaoFilter}</p>
            <div className="icon-parceiro">
              {mapaParceiro ? (
                <div>
                  <a href={mapaParceiro} target="blank">
                    {' '}
                    <NavigationIcon style={{ color: corCustom }} />
                  </a>
                </div>
              ) : null}

              {siteParceiro ? (
                <div>
                  <a href={siteParceiro} target="blank">
                    <PublicIcon style={{ color: corCustom }} />
                  </a>
                </div>
              ) : null}

              {instagramParceiro ? (
                <div>
                  <a href={instagramParceiro} target="blank">
                    <InstagramIcon style={{ color: corCustom }} />
                  </a>
                </div>
              ) : null}

              {facebookParceiro ? (
                <div>
                  <a href={facebookParceiro} target="blank">
                    <FacebookIcon style={{ color: corCustom }} />
                  </a>
                </div>
              ) : null}

              {whatsappParceiro ? (
                <div>
                  <a href={whatsappParceiro} target="blank">
                    <WhatsAppIcon style={{ color: corCustom }} />
                  </a>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <hr className="linha" />
      </div>

      {/* {logado ? (
        <div className="MainGrid" style={{ marginTop: '5px' }}>
          {nameFilter.map((item) => (
            <div key={item.id}>
              <div className="cont-card-parceiro">
                <div className="container-card">
                  <div
                    className="cupom-card cupom-card-perfil"
                    style={{
                      background: `linear-gradient(135deg, ${item.custom_color1} , ${item.custom_color2} )`,
                      marginBottom: '10px',
                    }}
                  >
                    <div className="div-img-cupom">
                      {!item.custom_logo && 'null' ? (
                        <img
                          src={
                            `${REACT_APP_URL_API}/media/` +
                            item.logo_empresa_parceira
                          }
                          className="logo-cupom"
                          id={item.id}
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${REACT_APP_URL_API}` + item.custom_logo}
                          className="logo-cupom"
                          id={item.id}
                          alt=""
                        />
                      )}
                    </div>
                    <h2 className="desconto" id={item.id}>
                      {item.desconto}
                    </h2>
                    <div className="container-descricao">
                      <h2 id={item.id}>{item.descricao}</h2>
                    </div>
                    <p> valido até {item.controle[2]}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="MainGrid" style={{ marginTop: '5px' }}>
          <div className="div-login">
            <p
              style={{
                color: 'var(--title)',
                padding: '10px',
                marginBottom: '20px',
              }}
            >
              Você não está logado. Para visualizar os vouchers da empresa{' '}
              {name_parceiro}, você precisa fazer o login ou se cadastrar.
            </p>
            <div className="cont-btn-login">
              <ButtonLink to={`/${cliente_id}/auth`}> Fazer login</ButtonLink>
            </div>
          </div>
        </div>
      )} */}
      <div className="MainGrid" style={{ marginTop: '5px' }}>
        {nameFilter.map((item) => (
          <div key={item.id}>
            <div className="box container-card-voucher">
              <div className="voucher-container card-voucher-parceiro">
                <div className="div-img-cupom">
                  {!item.custom_logo && 'null' ? (
                    <img
                      src={
                        `${REACT_APP_URL_API}/media/` +
                        item.logo_empresa_parceira
                      }
                      className="logo-cupom logo-parceiro-perfil"
                      id={item.id}
                      alt=""
                    />
                  ) : (
                    <img
                      src={`${REACT_APP_URL_API}` + item.custom_logo}
                      className="logo-cupom logo-parceiro-perfil"
                      id={item.id}
                      alt=""
                    />
                  )}
                </div>
                <div className="container-descricao">
                  <p id={item.id}>{item.descricao}</p>
                </div>

                <div className="box-button btn-ver-voucher">
                  {item.controle[0] === null && (
                    <button
                      style={{
                        background: 'var(--secondary)',
                        color: 'var(--background)',
                      }}
                      onClick={handleAbrir}
                    >
                      Ver voucher
                    </button>
                  )}

                  {whoami.isAuthenticated !== false &&
                  item.controle[0] === 'create' ? (
                    <button
                      id="cupom.id"
                      onClick={() => handleOpen(item.id)}
                      style={{
                        backgroundImage: `linear-gradient(135deg, ${item.custom_color1} , ${item.custom_color2} )`,
                      }}
                    >
                      Gerar voucher
                    </button>
                  ) : null}

                  {whoami.isAuthenticated !== false &&
                    item.controle[0] == 'show' && (
                      <button
                        style={{
                          background: 'var(--background-main)',
                          border: 'dashed 1px var(--primary)',
                          color: 'var(--primary)',
                        }}
                        id="cupom.id"
                        onClick={() => handleOpen2(item.id)}
                      >
                        Ver voucher
                      </button>
                    )}
                </div>
              </div>
            </div>

            {item.controle[0] === 'show' ? (
              <Modal
                open={item.id === open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id={item.id}
              >
                <div className="container-card">
                  <div>
                    <h1 className="description">Voucher gerado com sucesso</h1>
                  </div>

                  <div
                    className="cupom-card"
                    style={{
                      background: `linear-gradient(135deg, ${item.custom_color1} , ${item.custom_color2} )`,
                    }}
                  >
                    <div className="div-img-cupom">
                      <img
                        src={`${REACT_APP_URL_API}` + logoCliente}
                        className="logo-cupom"
                      />
                      {!item.custom_logo && 'null' ? (
                        <img
                          src={
                            `${REACT_APP_URL_API}/media/` +
                            item.logo_empresa_parceira
                          }
                          className="logo-cupom"
                          id={item.id}
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${REACT_APP_URL_API}` + item.custom_logo}
                          className="logo-cupom"
                          id={item.id}
                          alt=""
                        />
                      )}
                    </div>
                    <h2 className="desconto" id={item.id}>
                      {item.desconto}
                    </h2>

                    <div className="container-descricao">
                      <h2 id={item.id}>{item.descricao}</h2>
                    </div>

                    <div className="cupom-row">
                      {/* <span className="cupom-code"></span> */}

                      {item.controle[0] === 'show' ? (
                        <span className="cupom-code">{item.controle[1]}</span>
                      ) : null}
                    </div>

                    <p> valido até {item.controle[2]}</p>

                    <button className="fechar" onClick={handleClose}>
                      X
                    </button>
                  </div>
                </div>
              </Modal>
            ) : null}

            {whoami.isAuthenticated !== false ? (
              <Modal
                open={item.id === open2}
                onClose={handleClose2}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                id={item.id}
              >
                <div className="container-card">
                  <div>
                    <h1 className="description">Voucher gerado com sucesso</h1>
                  </div>

                  <div
                    className="cupom-card"
                    style={{
                      background: `linear-gradient(135deg, ${item.custom_color1} , ${item.custom_color2} )`,
                    }}
                  >
                    <div className="div-img-cupom">
                      <img
                        src={`${REACT_APP_URL_API}` + logoCliente}
                        className="logo-cupom"
                      />
                      {!item.custom_logo && 'null' ? (
                        <img
                          src={
                            `${REACT_APP_URL_API}/media/` +
                            item.logo_empresa_parceira
                          }
                          className="logo-cupom"
                          id={item.id}
                          alt=""
                        />
                      ) : (
                        <img
                          src={`${REACT_APP_URL_API}` + item.custom_logo}
                          className="logo-cupom"
                          id={item.id}
                          alt=""
                        />
                      )}
                    </div>
                    <h2 className="desconto" id={item.id}>
                      {item.desconto}
                    </h2>

                    <div className="container-descricao">
                      <h2 id={item.id}>{item.descricao}</h2>
                    </div>

                    <div className="cupom-row">
                      {/* <span className="cupom-code"></span> */}

                      {item.controle[0] === 'show' ? (
                        <span className="cupom-code">{item.controle[1]}</span>
                      ) : null}
                    </div>

                    <p> valido até {item.controle[2]}</p>

                    <button className="fechar" onClick={handleClose2}>
                      X
                    </button>
                  </div>
                </div>
              </Modal>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Parceiro;
