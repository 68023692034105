import React, { useState, useEffect } from "react";
import { Header } from "../../../Components/Header";
import Countdown from "../../../Countdown";
import Cookies from "universal-cookie";
import { useParams } from "react-router-dom";
import Loading from "../../../Components/Loading";
import SlotMachine from "../SlotMachine/SlotMachine";
import Resultado from "../Resultado";

const { REACT_APP_URL_API } = process.env;

const Time = () => {
  let [data, setData] = React.useState([]);
  let [sorteio, setSorteio] = React.useState([]);
  const { cliente_id } = useParams();
  const { id } = useParams();

  const teste = cliente_id;

  var raw = JSON.stringify({
    client_id: teste,
  });

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("csrftoken");
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio/`, {
        method: "POST",
        credentials: "include",
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  }, []);

  var raw2 = JSON.stringify({
    client_id: teste,
    sorteio_id: id,
  });

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get("csrftoken");
    const headers = new Headers({
      "Content-Type": "application/json",
      "X-CSRFToken": token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio_screen_controller/`, {
        method: "POST",
        credentials: "include",
        headers,
        body: raw2,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setSorteio(json);
      });
  }, []);

  // const nomeSorteio = data[0]?.response.filter((item) => item.id == id)[0].nome;
  const tempo = parseInt(sorteio[0]?.timer);

  // if (sorteio[0]?.screen === 'during') {
  //   <SlotMachine />;
  // }
  if (sorteio[0]?.screen === "during") {
    return <SlotMachine />;
  }

  if (sorteio[0]?.screen === "after") {
    return <Resultado />;
  }
  // return window.location.replace('slotmachine');

  if (data === null) return null;
  if (sorteio === null) return null;

  const nomeSorteio = data[0]?.response.filter((item) => item.id == id)[0].nome;
  const fotoSorteio = data[0]?.response.filter((item) => item.id == id)[0]
    .imagem;

  const dataSorteio = data[0]?.response.filter((item) => item.id == id)[0]
    .data_sorteio;

  if (isNaN(tempo)) {
    return;
  }

  const dt = new Date(Date.now() + sorteio[0]?.timer * 1000).toString();

  return (
    <>
      {/* <Header /> */}

      <div>
        <div className="div-sorteio-atual card-painel box ">
          {fotoSorteio ? (
            <img
              src={`${REACT_APP_URL_API}` + fotoSorteio}
              alt=""
              style={{ objectFit: "scale-down" }}
            />
          ) : (
            <img
              src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
              alt=""
            />
          )}

          <div>
            <h1
              className="title-sorteio-timer-painel"
              style={{ color: "var(--title)" }}
            >
              {nomeSorteio}
            </h1>
            <p>{dataSorteio}</p>
          </div>
        </div>

        <Countdown datetime={dt} style={{ marginTop: "200px" }} />
      </div>
    </>
  );
};

export default Time;
