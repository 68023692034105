import React, { useEffect } from 'react';
import './style.css';
import { IconContext } from 'react-icons';
import {
  AiOutlineInstagram,
  AiOutlineYoutube,
  AiOutlineFacebook,
  AiOutlineTwitter,
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
} from 'react-icons/ai';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

import { GlobalContext } from '../../GlobalContext';
import { Link, useParams } from 'react-router-dom';

import LogoClienteFacilities from '../LogoClienteFacilities';

const { REACT_APP_URL_API } = process.env;

export function HeaderFacilities() {
  const sair = () => {
    localStorage.removeItem('@user');

    var allCookies = document.cookie.split(';');

    window.location.reload();
  };

  const { data, item } = React.useContext(GlobalContext);
  const logado = localStorage.getItem('@user');

  let [social, setSocial] = React.useState(null);
  const { cliente_id } = useParams();
  const { servicos } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setSocial(json));
  }, []);

  const mainURL = window.location.href;
  const mainUrlParam = mainURL.split('/');
  const protocol = mainUrlParam[4];
  {
    console.log('protocol', protocol);
  }
  console.log('main', protocol);

  if (social === null) return null;

  return (
    <header className="header">
      <div className="container" style={{ gap: '0px' }}>
        {protocol !== 'facilities' ? (
          <a href=" javascript:history.back() " className="btn-header-return">
            <ArrowBackIosIcon />
          </a>
        ) : null}

        <LogoClienteFacilities />

        <div className="menu-icons">
          <IconContext.Provider value={{ size: '26px' }}>
            <div>
              {' '}
              <a href="#" target="blank">
                <AiFillInstagram />
              </a>
            </div>

            <div>
              <a href="#" target="blank">
                <AiFillYoutube />
              </a>
            </div>

            <div>
              {/* {logado ? (
                <AvatarMenu />
              ) : (
                <Link to={`/${cliente_id}/auth`}>
                  {' '}
                  <FaUserCircle className="icon-login" />
                </Link>
              )} */}
              {/* <AvatarBasic /> */}

              <div>
                <div className="card_room">
                  <RoomServiceIcon />
                  <span>9999</span>
                </div>
              </div>
            </div>
          </IconContext.Provider>
        </div>
      </div>
    </header>
  );
}
