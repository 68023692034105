import React, { useState, useEffect } from 'react';
import './style.css';
import { HeaderFacilities } from '../../../Components/HeaderFacilities';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const { REACT_APP_URL_API } = process.env;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'var(--table)',
    color: 'var(--primary)',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    color: 'var(--secondary)',
    backgroundColor: 'var(--table)',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'var(--background)',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(date, description, value) {
  return { date, description, value };
}

const rows = [
  createData('19/05/2019 01:40:00', 'Diaria / Rate', 'R$ 265.00'),
  createData('19/05/2019 01:40:00	', '	ISS / Service tax	', 'R$ 13.25'),
  createData(
    '19/05/2019 01:40:00',
    'Estacionamento/ Valet Parking	 / Rate',
    'R$ 60.00',
  ),
  createData('19/05/2019 01:40:00', 'Room Service	', 'R$ 44.00'),
  createData('19/05/2019 01:40:00', 'Frigobar / Mini-bar', 'R$ 7.50'),
];

export const Extrato = () => {
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();
  const teste = cliente_id;
  const [error, setError] = useState(null);
  var raw = JSON.stringify({
    client_id: teste,
  });
  const cookies = new Cookies();
  const token = cookies.get('csrftoken');

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });
  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);
  if (data === null) return null;
  const cor1 = data[0]?.response.cor_custom;
  const cor2 = data[0]?.response.cor_custom2;
  return (
    <div>
      <HeaderFacilities />
      <div className="MainGrid">
        <div className="firstColumn">
          <div className="div-extrato-cliente-name">
            <h1>{cliente_id}</h1>
            <p>
              <b>Nome:</b> RENATO PARDO
            </p>
            <p>
              {' '}
              <b> UH:</b> 0605
            </p>
            <p>
              {' '}
              <b>Reserva: </b> 223332455
            </p>
            <p>
              {' '}
              <b>Entrada:</b> 01/05/2019
            </p>
            <p>
              {' '}
              <b>Saida:</b> 31/12/2020
            </p>
          </div>

          <div className="div-extrato-table">
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Data/Hora</StyledTableCell>
                    <StyledTableCell>Descrição</StyledTableCell>
                    <StyledTableCell>Valor</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.index}>
                      <StyledTableCell component="th" scope="row">
                        {row.date}
                      </StyledTableCell>
                      <StyledTableCell>{row.description}</StyledTableCell>
                      <StyledTableCell>{row.value}</StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div
            className="div-extrato-cliente-name"
            style={{ marginTop: '20px' }}
          >
            <p>
              {' '}
              <b>Total:</b> 389.75
            </p>
            <button className="btn-chackout" style={{ background: cor1 }}>
              Solicitar Checkout{' '}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
