import React, { useCallback, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import { GlobalContext } from '../../../GlobalContext';
import './cropper.css';
import { storage } from '../../../Services/firebase';
import { ref, getDownloadURL, uploadBytesResumable } from 'firebase/storage';

const { REACT_APP_URL_API } = process.env;

const ImageCropper = ({
  onCropImage,
  inputImg,
  imgName,
  closeModal,
  ratio,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [loading, setLoading] = useState(false);
  const { item } = React.useContext(GlobalContext);
  const [imgURL, setImgURL] = useState('');
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(inputImg, croppedAreaPixels);
      onCropImage(
        new File([croppedImage], imgName, {
          type: '',
          lastModified: new Date().getTime(),
        }),
      );
    } catch (e) {
      console.error(e);
    }
    // eslint-disable-next-line
  }, [croppedAreaPixels]);

  const editProfile = (e) => {
    localStorage.setItem('edit', true);
  };
  if (data === null) return null;
  console.log('inputImg', inputImg);
  return (
    /* need to have a parent with `position: relative` 
    to prevent cropper taking up whole page */

    <div className="cropper">
      <div>
        <Cropper
          minZoom={0.4}
          image={inputImg}
          crop={crop}
          zoom={zoom}
          aspect={1}
          restrictPosition={false}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
          style={{
            containerStyle: {
              width: 500,
              height: 500,
              position: 'relative',
            },
          }}
        />
      </div>
      <div className="d-flex justify-content-between align-items-center mt-5 container-slide-modal">
        <input
          type="range"
          defaultValue={zoom}
          value={zoom}
          max={3.2}
          min={ratio ? 0.4 : 0.6}
          step={0.1}
          onChange={(e) => setZoom(e.target.value)}
        />

        <div className="container-btn-modal">
          <button
            onClick={closeModal}
            className="btn-cancel"
            style={{
              border: `1px solid ${data[0]?.response.cor_custom}`,
              color: `${data[0]?.response.cor_custom}`,
            }}
          >
            Cancelar
          </button>
          <button
            style={{
              background: `linear-gradient(135deg, ${data[0]?.response.cor_custom} 75%, ${data[0]?.response.cor_custom2} 100%)`,
              fontSize: '14px',
            }}
            onClick={() => {
              showCroppedImage();
              closeModal();
              editProfile();
            }}
            className="btn-save"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropper;
