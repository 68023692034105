import React, { useState, useEffect } from 'react';
import { GlobalContext } from '../../GlobalContext';
import { Link, useParams } from 'react-router-dom';
import LogoCliente from '../LogoCliente';
import Badge from '@mui/material/Badge';
import { styled, alpha } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import { IconContext } from 'react-icons';
import LogoClienteFacilities from '../LogoClienteFacilities';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import SearchIcon from '@mui/icons-material/Search';
import InputBase from '@mui/material/InputBase';

const { REACT_APP_URL_API } = process.env;

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));
const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '999',
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export function HeaderCardapio({ searchTerm, setSearchTerm }) {
  const { data, item } = React.useContext(GlobalContext);
  const logado = localStorage.getItem('@user');

  let [social, setSocial] = React.useState(null);
  const { cliente_id } = useParams();

  const cliente = cliente_id;

  var raw = JSON.stringify({
    client_id: cliente,
  });

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setSocial(json));
  }, []);

  if (social === null) return null;

  const color = social[0]?.response.cor_custom;
  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
      background: color,
    },
  }));

  const mainURL = window.location.href;
  const mainUrlParam = mainURL.split('/');

  return (
    <header className="header">
      <div className="container">
        <a href=" javascript:history.back() " className="btn-header-return">
          <ArrowBackIosIcon />
        </a>
        <LogoClienteFacilities />

        {mainUrlParam[4] == 'cardapio' ? (
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Buscar"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              inputProps={{ 'aria-label': 'search' }}
              style={{ height: '41px' }}
            />
          </Search>
        ) : null}

        <div className="menu-icons">
          <IconContext.Provider value={{ size: '26px' }}>
            <div>
              <div className="card_room">
                <RoomServiceIcon />
                <span>9999</span>
              </div>
            </div>
            <IconButton aria-label="cart">
              <a href="carrinho">
                <StyledBadge badgeContent={4} color="secondary">
                  <ShoppingCartIcon />
                </StyledBadge>
              </a>
            </IconButton>{' '}
          </IconContext.Provider>
        </div>
      </div>
    </header>
  );
}
