import React, { useState, useEffect } from 'react';
import { HeaderCardapio } from '../../../Components/HeaderCardapio';
import Box from '@mui/material/Box';
import './style.css';
import { Link } from 'react-scroll';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const { REACT_APP_URL_API } = process.env;
const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};

const FacilitiesCardapio = () => {
  let [data, setData] = React.useState(null);
  let [menu, setMenu] = React.useState(null);
  const { cliente_id } = useParams();
  const cliente = cliente_id;
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState(''); // Estado para o termo de busca
  var raw = JSON.stringify({
    client_id: cliente,
  });
  const cookies = new Cookies();
  const token = cookies.get('csrftoken');

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });
  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);

  // useEffect(() => {
  //   window
  //     .fetch(`${REACT_APP_URL_API}/apiv2/menu/`, {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json' },
  //       body: raw,
  //     })
  //     .then((response) => response.json())
  //     .then((json) => setMenu(json));
  // }, []);

  if (data === null) return null;
  // if (menu === null) return null;

  const cor1 = data[0]?.response.cor_custom;
  const cor2 = data[0]?.response.cor_custom2;

  const items = [
    {
      id: 1,
      categoria: 'cafe',
      nome: 'Café continental',
      descricao:
        'Suco de laranja natural ou melancia, frutas frescas da estação (2 tipos)...',
      preco: '29.90',
      imagem:
        'https://icafebr.com.br/wp-content/uploads/2022/09/tipos-cafe-continental-mesa-diferenca-entre-pequeno-almoco-e-buffet-cafe-salgado-receita-rapida.jpg',
    },
    {
      id: 2,
      categoria: 'cafe',
      nome: 'Café continental 2',
      descricao:
        'Suco de laranja natural ou melancia, frutas frescas da estação (2 tipos)...',
      preco: '39.90',
      imagem:
        'https://naminhaviagem.files.wordpress.com/2016/11/continental-breakfast.jpg?w=750&h=500',
    },
    {
      id: 3,
      categoria: 'almoco',
      nome: 'Picanha com fritas',
      descricao:
        'Suco de laranja natural ou melancia, frutas frescas da estação (2 tipos)...',
      preco: '59.90',
      imagem:
        'https://cdn6.campograndenews.com.br/uploads/noticias/2020/03/10/r4jueey894z2.jpg',
    },
    {
      id: 4,
      categoria: 'almoco',
      nome: 'Massa',
      descricao:
        'Suco de laranja natural ou melancia, frutas frescas da estação (2 tipos)...',
      preco: '99.90',
      imagem:
        'https://www.guiadaculinaria.com/wp-content/uploads/2021/04/diadasmaescapa.jpg',
    },
    {
      id: 5,
      categoria: 'almoco',
      nome: 'Lasanha',
      descricao: 'itaquaquecetuba',
      preco: '89.90',
      imagem:
        'https://italianorio.com.br/wp-content/uploads/2020/11/italian-spaghetti-with-tomato-sauce-pan_1220-4240.jpg',
    },
  ];

  const filteredItems = items.filter(
    (item) =>
      item.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.descricao.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div>
      <HeaderCardapio searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

      {/* <div className="search-bar">
        <input
          type="text"
          placeholder="Buscar no cardápio..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginTop: '100px' }}
        />
      </div> */}

      <div className="categorias categorias-cardapio">
        <ul>
          <li>
            <Link
              className="cafe"
              to="cafe"
              spy="cafe"
              smooth={true}
              duration={500}
              offset={-99}
              activeClass="ActiveLink"
            >
              Café da manhã
            </Link>
          </li>
          <li>
            <Link
              className="almoco"
              to="almoco"
              spy="almoco"
              smooth={true}
              duration={500}
              offset={-99}
              activeClass="ActiveLink"
            >
              Almoço
            </Link>
          </li>
        </ul>
      </div>

      <Box
        sx={{
          maxWidth: '930px',
          margin: '120px auto',
          left: '1%',
          right: '1%',
          bgcolor: 'var(--background-main)',
        }}
      >
        {filteredItems.length === 0 ? (
          <div className="no__results">
            <ErrorOutlineIcon />
            <p>Nenhum item encontrado.</p>
          </div>
        ) : (
          <>
            <div className="cafe" name="cafe" id="cafe">
              <div className="container-clube-voucher">
                <h2
                  style={{
                    color: 'var(--secondary)',
                    fontSize: '20px',
                    fontWeight: 300,
                    padding: '0px 0px 10px 10px',
                  }}
                >
                  Café da manhã
                </h2>
                {filteredItems.find(
                  (item) => item.categoria === 'cafe' && item.id === 1,
                ) && (
                  <a href="cardapioitem">
                    <div className="box container-card-cardapio">
                      <div className="cardapio-container">
                        <div>
                          <img src={items[0].imagem} alt="" />
                        </div>

                        <div className="counteudo-cardapio">
                          <p>
                            <b>{items[0].nome}</b>
                          </p>
                          <p>{items[0].descricao}</p>
                          <div className="div_cardapio_price">
                            <p className="price-cardapio">
                              R$ {items[0].preco}
                            </p>
                            <button style={{ background: cor1 }}>
                              Add ao Carrinho
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )}
                {filteredItems.find(
                  (item) => item.categoria === 'cafe' && item.id === 2,
                ) && (
                  <a href="cardapioitem">
                    <div className="box container-card-cardapio">
                      <div className="cardapio-container">
                        <div>
                          <img src={items[1].imagem} alt="" />
                        </div>

                        <div className="counteudo-cardapio">
                          <p>
                            <b>{items[1].nome}</b>
                          </p>
                          <p>{items[1].descricao}</p>
                          <div className="div_cardapio_price">
                            <p className="price-cardapio">
                              R$ {items[1].preco}
                            </p>
                            <button style={{ background: cor1 }}>
                              Add ao Carrinho
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )}
              </div>
            </div>

            <div className="almoco" name="almoco" id="almoco">
              <div className="container-clube-voucher">
                <h2
                  style={{
                    color: 'var(--secondary)',
                    fontSize: '20px',
                    fontWeight: 300,
                    padding: '0px 0px 10px 10px',
                  }}
                >
                  Almoço
                </h2>
                {filteredItems.find(
                  (item) => item.categoria === 'almoco' && item.id === 3,
                ) && (
                  <a href="cardapioitem">
                    <div className="box container-card-cardapio">
                      <div className="cardapio-container">
                        <div>
                          <img src={items[2].imagem} alt="" />
                        </div>

                        <div className="counteudo-cardapio">
                          <p>
                            <b>{items[2].nome}</b>
                          </p>
                          <p>{items[2].descricao}</p>
                          <div className="div_cardapio_price">
                            <p className="price-cardapio">
                              R$ {items[2].preco}
                            </p>
                            <button style={{ background: cor1 }}>
                              Add ao Carrinho
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )}
                {filteredItems.find(
                  (item) => item.categoria === 'almoco' && item.id === 4,
                ) && (
                  <a href="cardapioitem">
                    <div className="box container-card-cardapio">
                      <div className="cardapio-container">
                        <div>
                          <img src={items[3].imagem} alt="" />
                        </div>

                        <div className="counteudo-cardapio">
                          <p>
                            <b>{items[3].nome}</b>
                          </p>
                          <p>{items[3].descricao}</p>
                          <div className="div_cardapio_price">
                            <p className="price-cardapio">
                              R$ {items[3].preco}
                            </p>
                            <button style={{ background: cor1 }}>
                              Add ao Carrinho
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )}
                {filteredItems.find(
                  (item) => item.categoria === 'almoco' && item.id === 5,
                ) && (
                  <a href="cardapioitem">
                    <div className="box container-card-cardapio">
                      <div className="cardapio-container">
                        <div>
                          <img src={items[4].imagem} alt="" />
                        </div>

                        <div className="counteudo-cardapio">
                          <p>
                            <b>{items[4].nome}</b>
                          </p>
                          <p>{items[4].descricao}</p>
                          <div className="div_cardapio_price">
                            <p className="price-cardapio">
                              R$ {items[4].preco}
                            </p>
                            <button style={{ background: cor1 }}>
                              Add ao Carrinho
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                )}
                <div style={{ height: '400px' }}></div>
              </div>
            </div>
          </>
        )}
      </Box>
    </div>
  );
};

export default FacilitiesCardapio;
