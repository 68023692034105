import React, { useState } from 'react';
import ImageInput from './file/ImageInput';

const Demo = () => {
  const [image, setImage] = useState('');

  return (
    <div>
      <div>
        <ImageInput
          imageData={image.photo?.src}
          defaultPic="https://cdn-icons-png.flaticon.com/512/6596/6596121.png"
          type="admin"
          name="photo"
          label="Add Photo"
          showPreview
          onChange={(files) => setImage(files, 'admin')}
        />
      </div>
    </div>
  );
};

export default Demo;
