import React, { useState, useEffect } from 'react';
import { Header } from '../../Components/Header';
import './style.css';
import { FcNext } from 'react-icons/fc';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import ButtonLink from '../../Components/ButtonLink/ButtonLink';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { GlobalContext } from '../../GlobalContext';
import { AiTwotoneStar } from 'react-icons/ai';
import Modal from '@mui/material/Modal';
import Loading from '../../Components/Loading';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';

const { REACT_APP_URL_API } = process.env;

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};

const Eventos = () => {
  const { item } = React.useContext(GlobalContext);
  const { cliente_id } = useParams();
  const [value, setValue] = React.useState(0);

  const [participar, setParticipar] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let [data, setData] = React.useState(null);
  let [index, setIndex] = React.useState([]);
  const [abrir, setAbrir] = React.useState(0);

  const handleFechar = () => setAbrir(false);

  const [abrir1, setAbrir1] = React.useState(false);
  const [abrir2, setAbrir2] = React.useState(false);
  const [loading, setLoading] = useState(false);

  const handleAbrir1 = () => setAbrir1(true);
  const handleFechar1 = () => setAbrir1(false);

  const handleAbrir2 = () => setAbrir2(true);
  const handleFechar2 = () => setAbrir2(false);

  const teste = cliente_id;

  var raw = JSON.stringify({
    client_id: teste,
  });

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/evento/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  }, []);

  const eventoApi = () => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    // console.log('token', token);

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/evento/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  };

  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setIndex(json));
  }, []);

  const handleParticipar = (id) => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });
    fetch(`${REACT_APP_URL_API}/apiv2/evento_creation/`, {
      method: 'POST',
      credentials: 'include',
      headers,
      body: JSON.stringify({
        client_id: teste,
        evento_id: id,
      }),
    })
      .then((response) => response.json())

      .then((response) => {
        setParticipar(response.status);
      })

      .catch((erro) => console.log(erro));
    handleFechar();
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      window.location.reload();
    }, 1500);

    eventoApi();
  };

  const handleAbrir = (id) => {
    setAbrir(id);
  };

  const empresaId = item.company_id;

  if (data === null) return null;
  if (index === null) return null;

  function compare(a, b) {
    return a.data_evento < b.data_evento
      ? 1
      : a.data_evento > b.data_evento
      ? -1
      : 0;
  }
  if (loading)
    return (
      <div>
        {' '}
        <Loading />
      </div>
    );

  return (
    <div style={{ background: 'var(--background-main)' }}>
      <Header />
      <Box
        sx={{
          maxWidth: '930px',
          margin: '120px auto',
          left: '1%',
          right: '1%',
          bgcolor: 'var(--background-main)',
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={true}
          aria-label="scrollable prevent tabs example"
        >
          <Tab
            label="Eventos em andamento"
            style={{ color: 'var(--secondary)' }}
          />

          <Tab
            label="Eventos encerrados"
            style={{ color: 'var(--secondary)' }}
          />
        </Tabs>

        {value === 0 && (
          <div className="MainGrid" style={{ marginBottom: '-60px' }}>
            {data[0].response.map((item) => (
              <span>
                {cliente_id === 'hashtagsal' ? (
                  <div key={item.id}>
                    {item.evento_encerrado == false ? (
                      <div>
                        <div className="container-card-sorteio box">
                          {item.imagem ? (
                            // <img
                            //   src={`${REACT_APP_URL_API}` + item.imagem}
                            //   alt=""
                            // />

                            <PhotoProvider>
                              <PhotoView
                                src={`${REACT_APP_URL_API}` + item.imagem}
                              >
                                <img
                                  src={`${REACT_APP_URL_API}` + item.imagem}
                                  alt=""
                                />
                              </PhotoView>
                            </PhotoProvider>
                          ) : (
                            // <img
                            //   src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg"
                            //   alt="Evento"
                            // />

                            <PhotoProvider>
                              <PhotoView src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg">
                                <img
                                  src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg"
                                  alt=""
                                />
                              </PhotoView>
                            </PhotoProvider>
                          )}

                          {item.controle == 'create' && (
                            <div className="conteudo-card">
                              {item.evento_exclusivo === empresaId && (
                                <div
                                  className="div-exclusive-sorteio"
                                  style={{
                                    background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                  }}
                                >
                                  <p>
                                    Evento exclusivo{' '}
                                    <AiTwotoneStar
                                      style={{
                                        position: 'relative',
                                        top: '2px',
                                        left: '5px',
                                        color: '#f4af08',
                                      }}
                                    />
                                  </p>
                                </div>
                              )}

                              <h2>{item.nome}</h2>
                              <p>{item.descricao}</p>
                              <p>{item.data_evento}</p>

                              <span>
                                {item.evento_exclusivo === null && (
                                  <span>
                                    {item.evento_realizado === true ? (
                                      <ButtonLink
                                        to="#"
                                        className="btn-participar"
                                        style={{
                                          background: 'grey',
                                          opacity: '.5',
                                        }}
                                      >
                                        Indisponível
                                      </ButtonLink>
                                    ) : (
                                      <ButtonLink
                                        to="#"
                                        onClick={() => handleAbrir(item.id)}
                                        className="btn-participar"
                                      >
                                        Participar
                                      </ButtonLink>
                                    )}
                                  </span>
                                )}
                              </span>

                              {item.evento_exclusivo === empresaId && (
                                <span>
                                  {item.evento_realizado === true ? (
                                    <ButtonLink
                                      to="#"
                                      className="btn-participar"
                                      style={{
                                        background: 'grey',
                                        opacity: '.5',
                                      }}
                                    >
                                      Indisponível
                                    </ButtonLink>
                                  ) : (
                                    <ButtonLink
                                      to="#"
                                      onClick={() => handleAbrir(item.id)}
                                      className="btn-participar"
                                    >
                                      Participar
                                    </ButtonLink>
                                  )}
                                </span>
                              )}

                              {item.evento_exclusivo !== null &&
                                item.evento_exclusivo !== empresaId && (
                                  <ButtonLink
                                    to="#"
                                    className="btn-participar"
                                    onClick={handleAbrir2}
                                  >
                                    Participar
                                  </ButtonLink>
                                )}
                            </div>
                          )}

                          {item.controle[0] == 'show' && (
                            <div className="conteudo-card participando">
                              <h2>{item.nome}</h2>
                              <p>{item.descricao}</p>
                              <p>{item.data_evento}</p>

                              <button className="btn-participando" id="btn">
                                <span href="#">
                                  Você está participando, {item.controle[1]}!
                                </span>
                              </button>
                            </div>
                          )}
                          {item.controle[0] == null && (
                            <div className="conteudo-card participando">
                              <h2>{item.nome}</h2>
                              <p>{item.descricao}</p>
                              <p>{item.data_evento}</p>

                              <ButtonLink
                                to="#"
                                onClick={handleAbrir1}
                                className="btn-participar"
                              >
                                Participar
                              </ButtonLink>
                            </div>
                          )}
                          <Modal
                            open={item.id === abrir}
                            onClose={handleFechar}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            id={item.id}
                          >
                            <Box sx={style}>
                              <div>
                                <p
                                  style={{
                                    color: 'var(--title)',
                                    padding: '10px',
                                    marginBottom: '20px',
                                  }}
                                >
                                  Você confirma a participação no Evento?
                                </p>
                                <div>
                                  {' '}
                                  <ButtonLink
                                    to="#"
                                    // onClick={handleParticipar}
                                    onClick={() => handleParticipar(item.id)}
                                    className="btn-confrimar"
                                  >
                                    Confirmar
                                  </ButtonLink>
                                </div>

                                <button
                                  className="close"
                                  onClick={handleFechar}
                                  style={{
                                    background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  ///começa aqui

                  <div key={item.id}>
                    {item.evento_encerrado == false &&
                    item.evento_exclusivo === empresaId ? (
                      <div>
                        <div className="container-card-sorteio box">
                          {item.imagem ? (
                            // <img
                            //   src={`${REACT_APP_URL_API}` + item.imagem}
                            //   alt=""
                            // />

                            <PhotoProvider>
                              <PhotoView
                                src={`${REACT_APP_URL_API}` + item.imagem}
                              >
                                <img
                                  src={`${REACT_APP_URL_API}` + item.imagem}
                                  alt=""
                                />
                              </PhotoView>
                            </PhotoProvider>
                          ) : (
                            // <img
                            //   src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg"
                            //   alt="Evento"
                            // />

                            <PhotoProvider>
                              <PhotoView src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg">
                                <img
                                  src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg"
                                  alt=""
                                />
                              </PhotoView>
                            </PhotoProvider>
                          )}

                          {item.controle == 'create' && (
                            <div className="conteudo-card">
                              {/* {item.evento_exclusivo === empresaId && (
                                <div
                                  className="div-exclusive-sorteio"
                                  style={{
                                    background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                  }}
                                >
                                  <p>
                                    Evento exclusivo{' '}
                                    <AiTwotoneStar
                                      style={{
                                        position: 'relative',
                                        top: '2px',
                                        left: '5px',
                                        color: '#f4af08',
                                      }}
                                    />
                                  </p>
                                </div>
                              )} */}

                              <h2>{item.nome}</h2>
                              <p>{item.descricao}</p>
                              <p>{item.data_evento}</p>

                              {item.evento_exclusivo === empresaId && (
                                <span>
                                  {item.evento_realizado === true ? (
                                    <ButtonLink
                                      to="#"
                                      className="btn-participar"
                                      style={{
                                        background: 'grey',
                                        opacity: '.5',
                                      }}
                                    >
                                      Indisponível
                                    </ButtonLink>
                                  ) : (
                                    <ButtonLink
                                      to="#"
                                      onClick={() => handleAbrir(item.id)}
                                      className="btn-participar"
                                    >
                                      Participar
                                    </ButtonLink>
                                  )}
                                </span>
                              )}

                              {item.evento_exclusivo !== null &&
                                item.evento_exclusivo !== empresaId && (
                                  <ButtonLink
                                    to="#"
                                    className="btn-participar"
                                    onClick={handleAbrir2}
                                  >
                                    Participar
                                  </ButtonLink>
                                )}
                            </div>
                          )}

                          {item.controle[0] == 'show' && (
                            <div className="conteudo-card participando">
                              <h2>{item.nome}</h2>
                              <p>{item.descricao}</p>
                              <p>{item.data_evento}</p>

                              <button className="btn-participando" id="btn">
                                <span href="#">
                                  Você está participando, {item.controle[1]}!
                                </span>
                              </button>
                            </div>
                          )}
                          {item.controle[0] == null && (
                            <div className="conteudo-card participando">
                              <h2>{item.nome}</h2>
                              <p>{item.descricao}</p>
                              <p>{item.data_evento}</p>

                              <ButtonLink
                                to="#"
                                onClick={handleAbrir1}
                                className="btn-participar"
                              >
                                Participar
                              </ButtonLink>
                            </div>
                          )}
                          <Modal
                            open={item.id === abrir}
                            onClose={handleFechar}
                            aria-labelledby="modal-modal-title"
                            aria-describedby="modal-modal-description"
                            id={item.id}
                          >
                            <Box sx={style}>
                              <div>
                                <p
                                  style={{
                                    color: 'var(--title)',
                                    padding: '10px',
                                    marginBottom: '20px',
                                  }}
                                >
                                  Você confirma a participação no Evento?
                                </p>
                                <div>
                                  {' '}
                                  <ButtonLink
                                    to="#"
                                    // onClick={handleParticipar}
                                    onClick={() => handleParticipar(item.id)}
                                    className="btn-confrimar"
                                  >
                                    Confirmar
                                  </ButtonLink>
                                </div>

                                <button
                                  className="close"
                                  onClick={handleFechar}
                                  style={{
                                    background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                  }}
                                >
                                  X
                                </button>
                              </div>
                            </Box>
                          </Modal>
                        </div>
                      </div>
                    ) : null}

                    <span>
                      {item.evento_encerrado == false &&
                      item.evento_exclusivo === null ? (
                        <div>
                          <div className="container-card-sorteio box">
                            {item.imagem ? (
                              // <img
                              //   src={`${REACT_APP_URL_API}` + item.imagem}
                              //   alt=""
                              // />

                              <PhotoProvider>
                                <PhotoView
                                  src={`${REACT_APP_URL_API}` + item.imagem}
                                >
                                  <img
                                    src={`${REACT_APP_URL_API}` + item.imagem}
                                    alt=""
                                  />
                                </PhotoView>
                              </PhotoProvider>
                            ) : (
                              // <img
                              //   src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg"
                              //   alt="Evento"
                              // />

                              <PhotoProvider>
                                <PhotoView src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg">
                                  <img
                                    src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg"
                                    alt=""
                                  />
                                </PhotoView>
                              </PhotoProvider>
                            )}

                            {item.controle == 'create' && (
                              <div className="conteudo-card">
                                {/* {item.evento_exclusivo === empresaId && (
                            <div
                              className="div-exclusive-sorteio"
                              style={{
                                background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                              }}
                            >
                              <p>
                                Evento exclusivo{' '}
                                <AiTwotoneStar
                                  style={{
                                    position: 'relative',
                                    top: '2px',
                                    left: '5px',
                                    color: '#f4af08',
                                  }}
                                />
                              </p>
                            </div>
                          )} */}

                                <h2>{item.nome}</h2>
                                <p>{item.descricao}</p>
                                <p>{item.data_evento}</p>

                                {item.evento_exclusivo === null && (
                                  <span>
                                    {item.evento_realizado === true ? (
                                      <ButtonLink
                                        to="#"
                                        className="btn-participar"
                                        style={{
                                          background: 'grey',
                                          opacity: '.5',
                                        }}
                                      >
                                        Indisponível
                                      </ButtonLink>
                                    ) : (
                                      <ButtonLink
                                        to="#"
                                        onClick={() => handleAbrir(item.id)}
                                        className="btn-participar"
                                      >
                                        Participar
                                      </ButtonLink>
                                    )}
                                  </span>
                                )}
                              </div>
                            )}

                            {item.controle[0] == 'show' && (
                              <div className="conteudo-card participando">
                                <h2>{item.nome}</h2>
                                <p>{item.descricao}</p>
                                <p>{item.data_evento}</p>

                                <button className="btn-participando" id="btn">
                                  <span href="#">
                                    Você está participando, {item.controle[1]}!
                                  </span>
                                </button>
                              </div>
                            )}
                            {item.controle[0] == null && (
                              <div className="conteudo-card participando">
                                <h2>{item.nome}</h2>
                                <p>{item.descricao}</p>
                                <p>{item.data_evento}</p>

                                <ButtonLink
                                  to="#"
                                  onClick={handleAbrir1}
                                  className="btn-participar"
                                >
                                  Participar
                                </ButtonLink>
                              </div>
                            )}
                            <Modal
                              open={item.id === abrir}
                              onClose={handleFechar}
                              aria-labelledby="modal-modal-title"
                              aria-describedby="modal-modal-description"
                              id={item.id}
                            >
                              <Box sx={style}>
                                <div>
                                  <p
                                    style={{
                                      color: 'var(--title)',
                                      padding: '10px',
                                      marginBottom: '20px',
                                    }}
                                  >
                                    Você confirma a participação no Evento?
                                  </p>
                                  <div>
                                    {' '}
                                    <ButtonLink
                                      to="#"
                                      // onClick={handleParticipar}
                                      onClick={() => handleParticipar(item.id)}
                                      className="btn-confrimar"
                                    >
                                      Confirmar
                                    </ButtonLink>
                                  </div>

                                  <button
                                    className="close"
                                    onClick={handleFechar}
                                    style={{
                                      background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                                    }}
                                  >
                                    X
                                  </button>
                                </div>
                              </Box>
                            </Modal>
                          </div>
                        </div>
                      ) : null}
                    </span>
                  </div>
                )}
              </span>
            ))}
          </div>
        )}

        {value === 1 && (
          <div className="MainGrid" style={{ marginBottom: '-60px' }}>
            {data[0].response.sort(compare).map((item) => (
              <span key={item.id}>
                {cliente_id === 'hashtagsal' ? (
                  <div>
                    {item.evento_encerrado == true ? (
                      <div className="container-card-sorteio box">
                        {item.imagem ? (
                          <img
                            src={`${REACT_APP_URL_API}` + item.imagem}
                            alt=""
                          />
                        ) : (
                          <img
                            src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg"
                            alt=""
                          />
                        )}
                        <div className="conteudo-card participando">
                          <h2>{item.nome}</h2>
                          <p>{item.descricao}</p>
                          <p>{item.data_evento}</p>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : (
                  <div key={item.id}>
                    {item.evento_encerrado == true &&
                    item.evento_exclusivo === empresaId ? (
                      <div className="container-card-sorteio box">
                        {item.imagem ? (
                          <img
                            src={`${REACT_APP_URL_API}` + item.imagem}
                            alt=""
                          />
                        ) : (
                          <img
                            src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg"
                            alt=""
                          />
                        )}
                        <div className="conteudo-card participando">
                          <h2>{item.nome}</h2>
                          <p>{item.descricao}</p>
                          <p>{item.data_evento}</p>
                        </div>
                      </div>
                    ) : null}

                    <span>
                      {item.evento_encerrado == true &&
                      item.evento_exclusivo === null ? (
                        <div className="container-card-sorteio box">
                          {item.imagem ? (
                            <img
                              src={`${REACT_APP_URL_API}` + item.imagem}
                              alt=""
                            />
                          ) : (
                            <img
                              src="https://www.serro.mg.gov.br/fotos/b4d80b98de6ea9bb73c2ee183bad5ad2.jpg"
                              alt=""
                            />
                          )}
                          <div className="conteudo-card participando">
                            <h2>{item.nome}</h2>
                            <p>{item.descricao}</p>
                            <p>{item.data_evento}</p>
                          </div>
                        </div>
                      ) : null}
                    </span>
                  </div>
                )}
              </span>
            ))}
          </div>
        )}
      </Box>

      <Modal
        open={abrir1}
        onClose={handleFechar1}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p
              style={{
                color: 'var(--title)',
                padding: '10px',
                marginBottom: '20px',
              }}
            >
              Você não está logado
            </p>
            <div>
              <ButtonLink to={`/${cliente_id}/auth`}> Fazer login</ButtonLink>
            </div>
            <button
              className="close"
              onClick={handleFechar1}
              style={{
                background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
              }}
            >
              X
            </button>
          </div>
        </Box>
      </Modal>

      <Modal
        open={abrir2}
        onClose={handleFechar2}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {cliente_id === 'hashtagsal' ? (
          <Box sx={style}>
            <div>
              <p
                style={{
                  color: 'var(--title)',
                  padding: '10px',
                  marginBottom: '20px',
                }}
              >
                Evento Exclusivo para apoiadores acima de R$10,00. Acesse o
                Apoia-se para apoiar ou atualise o seu apoio agora.
              </p>

              <div>
                <div className="box-button">
                  <a
                    href="https://apoia.se/hashtagsal"
                    target="_blank"
                    className="button-voucher"
                    style={{
                      background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                    }}
                  >
                    Acessar
                  </a>
                </div>
              </div>

              <button
                className="close"
                onClick={handleFechar2}
                style={{
                  background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                }}
              >
                X
              </button>
            </div>
          </Box>
        ) : (
          <Box sx={style}>
            <div>
              <p
                style={{
                  color: 'var(--title)',
                  padding: '10px',
                  marginBottom: '20px',
                }}
              >
                Evento Exclusivo entre em contato com o administrador e saiba
                mais
              </p>

              <button
                className="close"
                onClick={handleFechar2}
                style={{
                  background: `linear-gradient(135deg, ${index[0]?.response.cor_custom} 75%, ${index[0]?.response.cor_custom2} 100%)`,
                }}
              >
                X
              </button>
            </div>
          </Box>
        )}
      </Modal>
    </div>
  );
};

export default Eventos;
