import React from 'react';

const symbol2emoji = {
  A: 'Ailson Alves Pereira ',
  B: 'Karol Goncalves Pereira',
  C: 'Cecilia Goncalves Pereira',
  D: 'Maria Odete Goncalves',
  E: 'Eduardo Goncalves Junio ',
  F: 'Juca Teixeira Pereira ',
  G: 'Ricardo Silva Pereira ',
  H: 'Margarida Pereira Dias',
};

const Reel = ({ symbol }) => (
  <div className="machine-reel">{symbol2emoji[symbol]}</div>
);

export default Reel;
