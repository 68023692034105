import React, { useState, useEffect } from 'react';
import './style.css';
import Box from '@mui/material/Box';
import { HeaderFacilities } from '../../../Components/HeaderFacilities';
import { Link } from 'react-scroll';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import MapIcon from '@mui/icons-material/Map';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import CallIcon from '@mui/icons-material/Call';
const { REACT_APP_URL_API } = process.env;
const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'var(--background)',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};
const Concierge = () => {
  let [data, setData] = React.useState(null);
  const { cliente_id } = useParams();
  const teste = cliente_id;
  const [error, setError] = useState(null);
  var raw = JSON.stringify({
    client_id: teste,
  });
  const cookies = new Cookies();
  const token = cookies.get('csrftoken');

  const headers = new Headers({
    'Content-Type': 'application/json',
    'X-CSRFToken': token,
  });
  useEffect(() => {
    window
      .fetch(`${REACT_APP_URL_API}/apiv2/index/`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: raw,
      })
      .then((response) => response.json())
      .then((json) => setData(json));
  }, []);
  if (data === null) return null;
  const cor1 = data[0]?.response.cor_custom;
  const cor2 = data[0]?.response.cor_custom2;

  return (
    <div>
      <HeaderFacilities />

      <div className="categorias categorias-cardapio">
        <ul>
          <li>
            <Link
              className="cafe"
              to="cafe"
              spy="cafe"
              smooth={true}
              duration={500}
              offset={-99}
              activeClass="ActiveLink"
            >
              Feiras{' '}
            </Link>
          </li>
          <li>
            <Link
              className="almoco"
              to="almoco"
              spy="almoco"
              smooth={true}
              duration={500}
              offset={-99}
              activeClass="ActiveLink"
            >
              Locais Próximos
            </Link>
          </li>
        </ul>
      </div>

      <Box
        sx={{
          maxWidth: '930px',
          margin: '120px auto',
          left: '1%',
          right: '1%',
          bgcolor: 'var(--background-main)',
        }}
      >
        <div className="cafe" name="cafe" id="cafe">
          <div className="container-clube-voucher">
            <h2
              style={{
                color: 'var(--secondary)',
                fontSize: '20px',
                fontWeight: 300,
                padding: '0px 0px 10px 10px',
              }}
            >
              Feiras
            </h2>

            <div>
              <div className="box container-card-cardapio">
                <div className="cardapio-container">
                  <div>
                    <img
                      src="https://upload.wikimedia.org/wikipedia/commons/d/de/Mercado_Municipal%2C_fachada_rua_da_Cantareira_2.JPG"
                      alt=""
                    />
                  </div>

                  <div className="counteudo-cardapio">
                    <p>
                      <b>Mercado Municipal</b>
                    </p>
                    <p>
                      Prédio histórico com praça de alimentação e boxes que
                      vendem frutas raras, verduras, legumes e laticínios.
                    </p>
                    <div className="div-btn-concierge">
                      <a href="#" style={{ background: cor1 }}>
                        <MapIcon />
                      </a>
                      <a href="#" style={{ background: cor1 }}>
                        <InstagramIcon />
                      </a>{' '}
                      <a href="#" style={{ background: cor1 }}>
                        <LanguageIcon />
                      </a>
                      <a href="#" style={{ background: cor1 }}>
                        <CallIcon />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* item 2 */}

            <div>
              <div className="box container-card-cardapio">
                <div className="cardapio-container">
                  <div>
                    <img
                      src="https://www.cnnbrasil.com.br/viagemegastronomia/wp-content/uploads/sites/5/2022/08/dia-do-feirante-feira-livre6.jpg?w=1200&h=675&crop=1"
                      alt=""
                    />
                  </div>

                  <div className="counteudo-cardapio">
                    <p>
                      <b>Feira Livre</b>
                    </p>
                    <p>
                      Feira do Velório da Vila Mariana. No Varejão Coopervar,
                      todas às sextas-feiras, 6h às 12 horas
                    </p>
                    <div className="div-btn-concierge">
                      <a href="#" style={{ background: cor1 }}>
                        <MapIcon />
                      </a>
                      <a href="#" style={{ background: cor1 }}>
                        <InstagramIcon />
                      </a>{' '}
                      <a href="#" style={{ background: cor1 }}>
                        <LanguageIcon />
                      </a>
                      <a href="#" style={{ background: cor1 }}>
                        <CallIcon />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="almoco" name="almoco" id="almoco">
          <div className="container-clube-voucher">
            <h2
              style={{
                color: 'var(--secondary)',
                fontSize: '20px',
                fontWeight: 300,
                padding: '0px 0px 10px 10px',
              }}
            >
              Locais Próximos
            </h2>

            <div>
              <div className="box container-card-cardapio">
                <div className="cardapio-container">
                  <div>
                    <img
                      src="https://www.spbairros.com.br/wp-content/uploads/2013/10/shopping-metro-santa-cruz.jpg"
                      alt=""
                    />
                  </div>

                  <div className="counteudo-cardapio">
                    <p>
                      <b>Shopping Santa Cruz</b>
                    </p>
                    <p>
                      Shopping com 132 lojas espalhadas pelos cinco andares,
                      além de uma praça de alimentação e um cinema.
                    </p>
                    <div className="div-btn-concierge">
                      <a href="#" style={{ background: cor1 }}>
                        <MapIcon />
                      </a>
                      <a href="#" style={{ background: cor1 }}>
                        <InstagramIcon />
                      </a>{' '}
                      <a href="#" style={{ background: cor1 }}>
                        <LanguageIcon />
                      </a>
                      <a href="#" style={{ background: cor1 }}>
                        <CallIcon />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="box container-card-cardapio">
                <div className="cardapio-container">
                  <div>
                    <img
                      src="https://vejasp.abril.com.br/wp-content/uploads/2017/12/bar-brahma-chope.jpg?quality=70&strip=info"
                      alt=""
                    />
                  </div>

                  <div className="counteudo-cardapio">
                    <p>
                      <b>Bar Brahma</b>
                    </p>
                    <p>
                      Música ao vivo, Chopp Brahma, ótimas comidas e muita
                      festa!
                    </p>
                    <div className="div-btn-concierge">
                      <a href="#" style={{ background: cor1 }}>
                        <MapIcon />
                      </a>
                      <a href="#" style={{ background: cor1 }}>
                        <InstagramIcon />
                      </a>{' '}
                      <a href="#" style={{ background: cor1 }}>
                        <LanguageIcon />
                      </a>
                      <a href="#" style={{ background: cor1 }}>
                        <CallIcon />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="box container-card-cardapio">
                <div className="cardapio-container">
                  <div>
                    <img
                      src="https://s3.amazonaws.com/smartsystem-prod/pictures/3159/big/smart-fit-academia-sao-paulo-santa-cruz-musculacao-peso-livre.jpg?1466788061"
                      alt=""
                    />
                  </div>

                  <div className="counteudo-cardapio">
                    <p>
                      <b>Acaddemia Smart Fit</b>
                    </p>
                    <p>A melhor academia do Brasil perto de você.</p>
                    <div className="div-btn-concierge">
                      <a href="#" style={{ background: cor1 }}>
                        <MapIcon />
                      </a>
                      <a href="#" style={{ background: cor1 }}>
                        <InstagramIcon />
                      </a>{' '}
                      <a href="#" style={{ background: cor1 }}>
                        <LanguageIcon />
                      </a>
                      <a href="#" style={{ background: cor1 }}>
                        <CallIcon />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ height: '400px' }}></div>
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Concierge;
