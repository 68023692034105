// import * as React from 'react';

// import './style.css';
// import { Header } from '../../../Components/Header';
// import { render } from 'react-dom';
// import { useEffect } from 'react';
// import { useState } from 'react';
// function Digit({ value }) {
//   return (
//     <div>
//       <div>
//         <div>{value}</div>
//       </div>
//     </div>
//   );
// }

// function Digits({ value }) {
//   const d1 = Math.floor(value / 10);
//   const d2 = Math.floor(value - d1 * 10);

//   return (
//     <div>
//       <Digit value={d1} />
//       <Digit value={d2} />
//     </div>
//   );
// }

// function Countdown({ targetTime }) {
//   let [now, setNow] = React.useState(new Date().getTime());
//   React.useEffect(() => {
//     const interval = setInterval(() => setNow(new Date().getTime()), 1000);
//     return () => clearInterval(interval);
//   }, []);

//   const millisecs = targetTime.getTime() - now;
//   const days = Math.floor(millisecs / (1000 * 3600 * 24));
//   let remainingMs = millisecs - days * 1000 * 3600 * 24;
//   const hours = Math.floor(remainingMs / (1000 * 3600));
//   remainingMs -= hours * 3600 * 1000;
//   const minutes = Math.floor(remainingMs / (1000 * 60));
//   remainingMs -= minutes * 1000 * 60;
//   const seconds = Math.floor(remainingMs / 1000);

//   const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(6);

//   // if (days < 0) {
//   //   break;
//   //   {

//   //     window.location.replace('slotmachine');
//   //   }
//   // }

//   return (
//     <div>
//       {' '}
//       <div className="MainGrid cont-clock">
//         <h2>O sorteio começará em:</h2>

//         <div className="container-cronometro">
//           <div className="relogio">
//             <span>
//               {' '}
//               <Digits value={days} />
//               <span className="tempo">Dias</span>
//             </span>

//             <span>
//               {' '}
//               <Digits value={hours} />
//               <span className="tempo">Horas</span>
//             </span>
//             <span>
//               {' '}
//               <Digits value={minutes} />
//               <span className="tempo">Minutos</span>
//             </span>
//             <span>
//               {' '}
//               <Digits value={seconds} />
//               <span className="tempo">Segundos</span>
//             </span>
//           </div>
//           <div></div>
//         </div>
//       </div>
//     </div>
//   );
// }

// function Cronometro() {
//   return (
//     <div>
//       <Countdown targetTime={new Date(2022, 9, 29, 20, 30)} />
//     </div>
//   );
// }

// const rootElement = document.getElementById('root');
// render(<Cronometro />, rootElement);

// export default Cronometro;
import './style.css';
import Cookies from 'universal-cookie';
import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { Header } from '../../../Components/Header';
import { useParams } from 'react-router-dom';
import Loading from '../../../Components/Loading';
import Modal from '@mui/material/Modal';
import ButtonLink from '../../../Components/ButtonLink/ButtonLink';

const { REACT_APP_URL_API } = process.env;
const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid var(--title)',
  boxShadow: 24,
  p: 4,
};
const Cronometro = () => {
  // const [totalTimeInSeconds, setTotalTimeInSeconds] = useState(3600);

  // const days = Math.round(totalTimeInSeconds / 86400);
  // const hours = Math.round(totalTimeInSeconds / 3600);
  // const minutes = Math.round(totalTimeInSeconds / 60);
  // const seconds = totalTimeInSeconds % 60;

  // useEffect(() => {
  //   if (totalTimeInSeconds === 0) {
  //     window.location.href = './slotmachine';
  //     return;
  //   } else {
  //     setTimeout(() => {
  //       setTotalTimeInSeconds(totalTimeInSeconds - 1);
  //     }, 1000);
  //   }
  // }, [totalTimeInSeconds]);
  const [abrir, setAbrir] = React.useState(false);
  const handleAbrir = () => setAbrir(true);
  const handleFechar = () => setAbrir(false);

  let [data, setData] = React.useState([]);
  const { cliente_id } = useParams();
  const { id } = useParams();

  const teste = cliente_id;

  var raw = JSON.stringify({
    client_id: teste,
  });

  React.useEffect(() => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    // console.log('token', token);

    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    window
      .fetch(`${REACT_APP_URL_API}/apiv2/sorteio/`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: raw,
      })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        setData(json);
      });
  }, []);

  // const timerSorteio = data.map((item) => item.response);

  const timerSorteio = data[0]?.response.filter((item) => item.id == id)[0]
    .data_sorteio;

  console.log('id', timerSorteio);
  var deadline = new Date(`${timerSorteio}`).getTime();

  if (isNaN(deadline)) {
    return <Loading />;
  }

  console.log('deadline', deadline);
  var x = setInterval(function () {
    var now = new Date().getTime();
    var t = deadline - now;
    var days = Math.floor(t / (1000 * 60 * 60 * 24));
    var hours = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((t % (1000 * 60)) / 1000);
    document.getElementById('day').innerHTML = days;
    document.getElementById('hour').innerHTML = hours;
    document.getElementById('minute').innerHTML = minutes;
    document.getElementById('second').innerHTML = seconds;
    if (t < 0) {
      clearInterval(x);
      // window.location.replace('slotmachine');
      handleAbrir();
      document.getElementById('day').innerHTML = '0';
      document.getElementById('hour').innerHTML = '0';
      document.getElementById('minute').innerHTML = '0';
      document.getElementById('second').innerHTML = '0';
    }
  }, 1000);
  if (data === null) return null;

  return (
    <>
      <Header />
      <div className="MainGrid cont-clock">
        <div></div>
        <h2 style={{ color: 'var(--title)' }}>O sorteio começará em:</h2>
        <div className="container-cronometro">
          <div className="relogio">
            <div>
              <span id="day"> </span>
              <span className="tempo">Dias</span>
            </div>

            <div>
              <span id="hour"> </span>
              <span className="tempo">Horas</span>
            </div>

            <div>
              <span id="minute"> </span>
              <span className="tempo">Minutos</span>
            </div>

            <div>
              <span id="second"> </span>
              <span className="tempo">Segundos</span>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={abrir}
        onClose={handleFechar}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div>
            <p
              style={{
                color: 'var(--title)',
                padding: '10px',
                marginBottom: '20px',
              }}
            >
              Ocorreu um erro, entre em contato com o suporte.{' '}
            </p>
            <div>
              {/* <ButtonLink to="home" >Voltar ao inicio<ButtonLink/> */}
              <ButtonLink to="home">Voltar</ButtonLink>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Cronometro;
